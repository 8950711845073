import React, { useState, useEffect } from 'react';
import axios from "axios";
import {Button, Row, Col} from "react-bootstrap";
import {useHistory} from "react-router-dom";

export default function ChatRequestsPage({launchChat}) {
    const [requests, setrequests] = useState([]);
    const history = useHistory();

    useEffect(() => {
        axios.get(process.env.REACT_APP_BACKEND + '/chat_requests/get', {
            withCredentials: true
        }).then(response => {
            console.log(response.data);
            setrequests(response.data);
        })
        .catch(error => {
            console.log(error.response.data);
        })
    }, []);

    function RequestItem(props){
        let client = props.request.client;
        let icon = (client.icon)? client.icon : 1;
        return (
            <tr>
                <th scope="row">
                    <div className="media align-items-center">
                        <a href="#" className="avatar rounded-circle mr-3">
                            <img alt="Image placeholder" src={`/assets/img/profile_icons/${icon}.png`} />
                        </a>
                        <div className="media-body">
                            <span className="name mb-0 text-sm">{client.first_name} {client.last_name}</span>
                        </div>
                    </div>
                </th>
                <td>
                    {props.request.date}
                </td>
                <td>
                    {props.request.status}
                </td>
                <td className="text-left">
                    <Button onClick={()=> {launchChat(props.request.chatroom_id, client, 'QUICK_CHAT')}}>Accept</Button>
                </td>
            </tr>
        );
    }

    function loadrequests(){
        if(requests.length > 0){
            return requests.map((request) => {
                return <RequestItem request={request} />
            });
        }else{
            return (
                <tr>
                    <td colspan='6'><div className='alert alert-info text-center m-2'>No Requests found</div></td>
                    
                </tr>
            
            );
        }
    }



    return (
        <div>
            <div>
                <div className="header bg-primary pb-6">
                <div className="container-fluid">
                    <div className="header-body">
                    <div className="row align-items-center py-4">
                        <div className="col-lg-6 col-7">
                        <h6 className="h2 text-white d-inline-block mb-0">Chat requests</h6>
                        <nav aria-label="breadcrumb" className="d-none d-md-inline-block ml-md-4">
                            <ol className="breadcrumb breadcrumb-links breadcrumb-dark">
                            <li className="breadcrumb-item"><a href="/app"><i className="fas fa-home" /></a></li>
                            <li className="breadcrumb-item"><a href="/app">Dashboard</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Chat requests</li>
                            </ol>
                        </nav>
                        </div>
                        <div className="col-lg-6 col-5 text-right">
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                {/* Page content */}
                <div className="container-fluid mt--6">
                <div className="row">
                    <div className="col">
                    <div className="card">
                        {/* Card header */}
                        <div className="card-header border-0">
                        <h3 className="mb-0">All Chat requests</h3>
                        </div>
                        {/* Light table */}
                        <div className="table-responsive">
                        <table className="table align-items-center table-flush">
                            <thead className="thead-light">
                            <tr>
                                <th scope="col" className="sort" data-sort="name">Client</th>
                                <th scope="col" className="sort" data-sort="budget">Requested On</th>
                                <th scope="col" className="sort" data-sort="status">Status</th>
                                <th scope="col" >Actions</th>
                            </tr>
                            </thead>
                            <tbody className="list">
                            {loadrequests()}

                            </tbody>
                        </table>
                        </div>
                        {/* Card footer */}
                        <div className="card-footer py-4">
                        <nav aria-label="...">
                            <ul className="pagination justify-content-end mb-0">
                            <li className="page-item disabled">
                                <a className="page-link" href="#" tabIndex={-1}>
                                <i className="fas fa-angle-left" />
                                <span className="sr-only">Previous</span>
                                </a>
                            </li>
                            <li className="page-item active">
                                <a className="page-link" href="#">1</a>
                            </li>
                            <li className="page-item">
                                <a className="page-link" href="#">2 <span className="sr-only">(current)</span></a>
                            </li>
                            <li className="page-item"><a className="page-link" href="#">3</a></li>
                            <li className="page-item">
                                <a className="page-link" href="#">
                                <i className="fas fa-angle-right" />
                                <span className="sr-only">Next</span>
                                </a>
                            </li>
                            </ul>
                        </nav>
                        </div>
                    </div>
                    </div>
                </div>
                
                </div>
            </div>
        </div>
    );
    
}