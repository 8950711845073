import React, {Component, useState, useEffect, useRef} from "react";
import axios from "axios";
import Form from "react-bootstrap/Form";
import {Modal, Button, Alert} from "react-bootstrap";
import  io from "socket.io-client";
import {useSpring, animated} from "react-spring";
import {Spring} from 'react-spring/renderprops'
import Webcam from "react-webcam";

export default function ChatPage({current_user, showMessage}){
    const [videoLoaded, setVideoLoaded] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [chatroomID, setChatroomID] = useState(null);
    const [socket, setSocket] = useState(null);
    const [refresh, setRefresh] = useState(1);
    const blobList = useRef([]);
    const [currentMessage, setCurrentMessage] = useState(null);
    const messageInput = useRef();
    const webcamRef = useRef();
    const mediaRecorderRef = useRef(null);
    const mediaSourceRef = useRef(null);
    const sourceBufferRef = useRef(null);
    const [recordedChunks, setRecordedChunks] = useState([]);
    const [url, setUrl] = useState(null);
    const chunks = useRef([]);
    const currentTime = useRef(0);
    const otherFrames = useRef([]);
    const [currentOtherFrame, setCurrentOtherFrame] = useState(null);
    const [audioEnabled, setAudioEnabled] = useState(false);
    const otherUserJoined = useRef(false);
    const firstBlob = useRef(null);

    useEffect(()=>{
        joinMeeting();
    }, []);

    function joinMeeting(){
        mediaSourceRef.current = new MediaSource();
        mediaSourceRef.current.addEventListener(
            "sourceopen",
            ()=>{
                sourceBufferRef.current = mediaSourceRef.current.addSourceBuffer("video/webm; codecs=\"opus,vp8\"");
            }
        )
        const url = URL.createObjectURL(mediaSourceRef.current);
        setUrl(url);

        let meeting_id = 'abcd1234';
        if(socket){
            socket.disconnect();
        }
        axios({
            url: process.env.REACT_APP_BACKEND + '/users/joinMeeting/id/' + meeting_id,
            method: 'GET',
            withCredentials: true
        }).then(response => {
            let _chatroom_id = response.data;
            setChatroomID(_chatroom_id);

            //Start websocket connection
            const _socket = io(process.env.REACT_APP_BACKEND, {transports: ['websocket']});
            setSocket(_socket);
            sendSocketData(_chatroom_id, _socket, "user_joined");
            onSocketDataReceived(_chatroom_id, _socket);
            

        }).catch(error=>{
            console.log(error);
        });
    }

    function sendSocketData(_chatroom_id, _socket, message){
        let _message = {user_id: current_user._id, message: message};
        _socket.emit(_chatroom_id, _message);
    }

    function onSocketDataReceived(_chatroom_id, _socket){
        _socket.on(_chatroom_id, data => {
            let {user_id, message} = data;
            if(message === 'user_joined'){
                if(!otherUserJoined.current){
                    otherUserJoined.current = true;
                    sendSocketData(_chatroom_id, _socket, "user_joined");
                    showMessage("User Joined");
                }
                
            }else{
                appendBlobToBuffer(message);
                console.log(message);
            }
            
            setRefresh(refresh => refresh + 1);
            
        }); 
    }

    
    function handleDataAvailable({data}){
        if (data.size > 0) {
            if(!firstBlob.current){
                firstBlob.current = data;
            }
            sendSocketData(chatroomID, socket, data);
        }
    }

    function captureCurrentMediaRecorderBlob(){
        mediaRecorderRef.current.requestData();
    }

    async function appendBlobToBuffer(blob){
        if(mediaSourceRef.current.readyState === "open" &&
            sourceBufferRef.current &&
            sourceBufferRef.current.updating === false){
            let buffer = blob;
            sourceBufferRef.current.appendBuffer(buffer);
            setRefresh(d => d+1);
        }

    }

    function recordMedia(){
        
        

        mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
            mimeType: "video/webm;codecs=vp8,opus"
        });
        mediaRecorderRef.current.addEventListener(
            "dataavailable",
            handleDataAvailable
        );

        // mediaRecorderRef.current.addEventListener(
        //     "stop",
        //     handleStopRecording
        // );

        mediaRecorderRef.current.start();
        setInterval(captureCurrentMediaRecorderBlob, 1000);
    }


    

    return (
        <div className="meeting-player">
            <div className='meeting-player-sidebar'>
                <div className='side-bar-logo-container'>
                    <img src="/assets/img/brand/white.png" height="48"/>
                </div>
                <div className='side-bar-icon-container'>
                    <i className="fa fa-video text-white side-bar-icon"></i>
                </div>
                <div className='side-bar-icon-container'>
                    <i className="fa fa-microphone-slash text-white side-bar-icon"></i>
                </div>
                <div className='side-bar-icon-container'>
                    <i className="fa fa-volume-mute text-white side-bar-icon"></i>
                </div>
                <div className='side-bar-icon-container'>
                    <i className="fa fa-user text-white side-bar-icon"></i>
                </div>
                <div className='side-bar-icon-container log-out-btn'>
                    <i className="fa fa-sign-out-alt text-white side-bar-icon"></i>
                </div>
            </div>
            <div className='video-container'>
                <video  className='other-video' dt={refresh} src={url} autoPlay />
                {otherUserJoined.current && <Webcam audio={true} className='my-video' screenshotQuality={1} ref={webcamRef} onUserMedia={(stream)=>{setVideoLoaded(true); recordMedia()}} />}
                <i className="fa fa-phone-slash text-white end-call-icon"></i>
                <div className='timer-button'>
                    <i className="fa fa-clock text-white timer-icon">04:33</i>
                    <span className='timer-text'>left</span>
                </div>
                
            </div>
            
        </div>    
    )
}