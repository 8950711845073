import React, { useState, useEffect } from 'react';
import axios from "axios";
import {Button, Row, Col, Card, Form, Carousel} from "react-bootstrap";
import {useHistory, useParams} from "react-router-dom";

export default function CreateCounsellorPage({showMessage}) {
    const [username, setUsername] = useState(null);
    const [first_name, setFirstName] = useState(null);
    const [last_name, setLastName] = useState(null);
    const [password, setPassword] = useState(null);
    const [can_view_referrals, setcan_view_referrals] = useState(false);
    const [sub_user_type, setSub_user_type] = useState('PROFESSIONAL');
    const [icon, setIcon] = useState(1);
    const [refresh, setRefresh] = useState(0);

    const history = useHistory();
    const {id} = useParams();


    useEffect(() => {
        if(id){
            axios.get(process.env.REACT_APP_BACKEND + `/users/get_counsellor/${id}`, {
                withCredentials: true
            }).then(response => {
                let user = response.data;
                setUsername(user.username);
                setFirstName(user.first_name);
                setLastName(user.last_name);
                setIcon(user.icon);
                setcan_view_referrals(user.can_view_referrals);
                setSub_user_type(user.sub_user_type);
                setRefresh(refresh + 1);
                console.log(user);
            })
            .catch(error => {
                console.log(error.response.data);
            })
        }
    }, []);


    function onFormSubmit(){
        let user = {
            username,
            first_name,
            last_name,
            password,
            sub_user_type,
            can_view_referrals,
            icon
        }

        if(!id){
            axios({
                url: process.env.REACT_APP_BACKEND + '/users/create_counsellor',
                method: 'POST',
                data: user,
                withCredentials: true
            }).then(response => {
                showMessage('The user was created successfully');
                history.push('/app/counsellors');
            }).catch(error => {
                showMessage(error.response.data.message, false);
            })
        }else{
            axios({
                url: process.env.REACT_APP_BACKEND + '/users/update_counsellor/' + id,
                method: 'POST',
                data: user,
                withCredentials: true
            }).then(response => {
                showMessage('The user was updated successfully');
                history.push('/app/counsellors');
            }).catch(error => {
                showMessage(error.response.data.message, false);
            })
        }
    }
    return (
        <div>
            <div>
                <div className="header bg-primary pb-6">
                <div className="container-fluid">
                    <div className="header-body">
                    <div className="row align-items-center py-4">
                        <div className="col-lg-6 col-7">
                        <h6 className="h2 text-white d-inline-block mb-0">Counsellors</h6>
                        <nav aria-label="breadcrumb" className="d-none d-md-inline-block ml-md-4">
                            <ol className="breadcrumb breadcrumb-links breadcrumb-dark">
                            <li className="breadcrumb-item"><a href="/app"><i className="fas fa-home" /></a></li>
                            <li className="breadcrumb-item"><a onClick={()=> {history.push("/app/dashboard")}}>Dashboard</a></li>
                            <li className="breadcrumb-item"><a onClick={()=> {history.push("/app/counsellors")}}>Counsellors</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Edit Counsellor</li>
                            </ol>
                        </nav>
                        </div>
                        <div className="col-lg-6 col-5 text-right">
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                {/* Page content */}
                <div className="container-fluid mt--6">
                <Row>
                    <Col>
                        <Card className="p-5">
                            <Form>
                                <Row>
                                    <Col sm={6}>
                                        <h2 className="mb-5">Please enter details for the counsellor</h2>
                                        <Form.Group>
                                            <Form.Label>Enter a first name</Form.Label>
                                            <Form.Control onChange={e=>setFirstName(e.target.value)} type="text" placeholder="John" value={first_name} />
                                            <Form.Text>Min 3 characters</Form.Text>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Enter a last name</Form.Label>
                                            <Form.Control onChange={e=>setLastName(e.target.value)} type="text" placeholder="Baptist" value={last_name} />
                                            <Form.Text>Min 3 characters</Form.Text>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Enter a username</Form.Label>
                                            <Form.Control onChange={e=>setUsername(e.target.value)} type="text" autocomplete="off" placeholder="john876"  value={username} />
                                            <Form.Text>Min 5 characters</Form.Text>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Enter a password</Form.Label>
                                            <Form.Control onChange={e=>setPassword(e.target.value)} autocomplete="off" type="text" />
                                            <Form.Text>Min 8 characters, must contain at least 1 number and a letter</Form.Text>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Sub User Type</Form.Label>
                                            <Form.Control as="select" onChange={e=>setSub_user_type(e.target.value) } value={sub_user_type}>
                                                <option value="PROFESSIONAL">Professional Counsellor</option>
                                                <option value="PASTOR">Pastorial Counsellor</option>
                                                <option value="PRAYER_INTERCESSOR">Prayer Intercessor</option>
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Can View Referrals</Form.Label>
                                            <Form.Control as="select" onChange={e=>e.target.value == 'true'? setcan_view_referrals(true): setcan_view_referrals(false) } value={can_view_referrals? 'true': 'false'}>
                                                <option value='true'>Yes</option>
                                                <option value='false'>No</option>
                                            </Form.Control>
                                        </Form.Group>
                                        
                                    </Col>
                                    <Col sm={6}>
                                        <h2 className="mb-5">Please select an icon for the counsellor</h2>
                                        <Row className="p-3">
                                            <Col>
                                                <Carousel activeIndex={icon - 1} onSelect={(index, e)=>setIcon(index + 1)} interval={null} className="bg-default">
                                                    {[1,2,3,4,5].map(num=> (
                                                        <Carousel.Item className="text-center" key={num}>
                                                            <img src={`/assets/img/counsellor_profile_icons/${num}.png`} />
                                                        </Carousel.Item>
                                                    ))}
                                                </Carousel>
                                             </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Button onClick={onFormSubmit}>Save Counsellor</Button>
                            </Form>
                        </Card>
                    </Col>
                </Row>
                
                </div>
            </div>
        </div>
    );
    
}