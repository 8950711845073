import React from "react";
import ScriptTag from "react-script-tag";
import $ from 'jquery'; 

const FootData = props => (
    <footer>
        <ScriptTag type='text/javascript' src="/assets/js/modernizr.min.js"/>
        {/* <ScriptTag type='text/javascript' src="/assets/vendor/jquery/dist/jquery.min.js"/> */}
        <ScriptTag type='text/javascript' src="/assets/vendor/bootstrap/dist/js/bootstrap.bundle.min.js"/>
        <ScriptTag type='text/javascript' src="/assets/vendor/js-cookie/js.cookie.js"/>
        <ScriptTag type='text/javascript' src="/assets/vendor/jquery.scrollbar/jquery.scrollbar.min.js"/>
        <ScriptTag type='text/javascript' src="/assets/vendor/jquery-scroll-lock/dist/jquery-scrollLock.min.js"/>
        <ScriptTag type='text/javascript' src="/assets/vendor/chart.js/dist/Chart.min.js"/>
        <ScriptTag type='text/javascript' src="/assets/vendor/chart.js/dist/Chart.extension.js"/>
        <ScriptTag type='text/javascript' src="/assets/js/argon.js?v=1.2.0"/>
        <ScriptTag type='text/javascript' src="/assets/js/jquery-ui.min.js"/>
        <ScriptTag type='text/javascript' src="/assets/js/custom.js"/>
        
    </footer>
);

export default FootData;