import React, {Component} from "react";
import axios from "axios";
import {Card, Button, Alert, Row, Col} from "react-bootstrap";

export default class DashboardPage extends Component {
    constructor(props){
        super(props);
        
        this.state = {
            users: []
        }
    }

    componentDidMount(){
        
    }

    render() {
        return (
            <div>
                <div>
                    <div className="header bg-primary pb-6">
                    <div className="container-fluid">
                        <div className="header-body">
                        <div className="row align-items-center py-4">
                            <div className="col-lg-6 col-7">
                            <h6 className="h2 text-white d-inline-block mb-0">My Dashboard</h6>
                            <nav aria-label="breadcrumb" className="d-none d-md-inline-block ml-md-4">
                                <ol className="breadcrumb breadcrumb-links breadcrumb-dark">
                                <li className="breadcrumb-item"><a href="/app"><i className="fas fa-home" /></a></li>
                                <li className="breadcrumb-item"><a href="/app">Dashboard</a></li>
                                <li className="breadcrumb-item active" aria-current="page">My Dashboard</li>
                                </ol>
                            </nav>
                            </div>
                            <div className="col-lg-6 col-5 text-right">
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    {/* Page content */}
                    <div className="container-fluid mt--6">
                    <Row>
                        <Col sm>
                            <Card bg="warning  text-white">
                                {/* <Card.Img src="https://cdn.pixabay.com/photo/2016/10/23/17/06/calendar-1763587_1280.png" /> */}
                                <Card.Header className="bg-warning text-white">Manage My Schedule</Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        Create a schedule based on your availability. You will only be booked for appointments based on your schedule.
                                    </Card.Text>
                                    <Button onClick={()=>{window.location = '/app/schedule';}} variant="dark">Manage My Schedule</Button>
                                </Card.Body>
                                
                            </Card>
                        </Col>
                        <Col sm>
                            <Card bg="dark  text-white">
                                {/* <Card.Img src="https://cdn.pixabay.com/photo/2016/10/23/17/06/calendar-1763587_1280.png" /> */}
                                <Card.Header className="bg-dark text-white">Manage My Appointments</Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        Check here to see if you have any new appointment requests, upcomming appointments or manage existing appointments.
                                    </Card.Text>
                                    <Button variant="warning">Manage My Appointments</Button>
                                </Card.Body>
                                
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm>
                            <Card bg="primary  text-white">
                                {/* <Card.Img src="https://cdn.pixabay.com/photo/2016/10/23/17/06/calendar-1763587_1280.png" /> */}
                                <Card.Header className="bg-primary text-white">Manage My Clients</Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        Manage all your clients from past and upcomming appointments. View your client profiles, make personalised notes etc.
                                    </Card.Text>
                                    <Button variant="success">Manage My Clients</Button>
                                </Card.Body>
                                
                            </Card>
                        </Col>
                        <Col sm>
                            <Card bg="success  text-white">
                                {/* <Card.Img src="https://cdn.pixabay.com/photo/2016/10/23/17/06/calendar-1763587_1280.png" /> */}
                                <Card.Header className="bg-success text-white">View Reports</Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        How have your clients been improving? Keep track of your clients' performance and progress.
                                    </Card.Text>
                                    <Button variant="primary">View Reports</Button>
                                </Card.Body>
                                
                            </Card>
                        </Col>
                    </Row>
                    </div>
                </div>
            </div>
        );
    }
}