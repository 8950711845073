import React, { useState, useEffect } from 'react';
import axios from "axios";
import {Button, Row, Col} from "react-bootstrap";
import {useHistory} from "react-router-dom";

export default function ArticlesPage({showMessage}) {
    const [articles, setArticles] = useState([]);
    const history = useHistory();

    useEffect(() => {
        axios.get(process.env.REACT_APP_BACKEND + '/article/get_articles', {
            withCredentials: true
        }).then(response => {
            setArticles(response.data);
        })
        .catch(error => {
            console.log(error.response.data);
        })
    }, []);

    function deleteArticle(id){
        axios.get(process.env.REACT_APP_BACKEND + '/article/delete/'+id, {
            withCredentials: true
        }).then(response => {
            showMessage('Article deleted successfully');
            history.go(0);
        })
        .catch(error => {
            console.log(error.response.data);
        })
    }

    function ArticleItem({article}){
        return (
            <tr>
                <td>
                    {article.title}
                </td>
                <td>
                    {new Date(article.scheduledDate).toDateString()}
                </td>
                <td>
                    {article.active? 'ACTIVE' : 'INACTIVE'}
                </td>
                <td>
                    <Button onClick={()=>{history.push(`/app/edit_article/${article._id}`)}} className='btn-info'><i className='ni ni-settings'></i></Button>
                    <Button onClick={()=>{history.push(`/show_article/${article._id}`)}} className='btn-info'><i className='fa fa-eye'></i></Button>
                    <Button onClick={()=>deleteArticle(article._id)} className='btn-danger'><i className='ni ni-fat-remove'></i></Button>
                </td>
            </tr>
        );
    }

    function loadArticles(){
        if(articles.length > 0){
            return articles.map((Article) => {
                return <ArticleItem article={Article} />
            });
        }else{
            return (
                <tr>
                    <td colspan='6'><div className='alert alert-info text-center m-2'>No Articles found</div></td>
                    
                </tr>
            
            );
        }
    }



    return (
        <div>
            <div>
                <div className="header bg-primary pb-6">
                <div className="container-fluid">
                    <div className="header-body">
                    <div className="row align-items-center py-4">
                        <div className="col-lg-6 col-7">
                        <h6 className="h2 text-white d-inline-block mb-0">Articles</h6>
                        <nav aria-label="breadcrumb" className="d-none d-md-inline-block ml-md-4">
                            <ol className="breadcrumb breadcrumb-links breadcrumb-dark">
                            <li className="breadcrumb-item"><a href="/app"><i className="fas fa-home" /></a></li>
                            <li className="breadcrumb-item"><a href="/app">Dashboard</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Articles</li>
                            </ol>
                        </nav>
                        </div>
                        <div className="col-lg-6 col-5 text-right">
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                {/* Page content */}
                <div className="container-fluid mt--6">
                <div className="row">
                    <div className="col">
                    <div className="card">
                        {/* Card header */}
                        <div className="card-header border-0">
                        <h3 className="mb-0">All Articles</h3>
                        </div>
                        {/* Light table */}
                        <div className="text-right p-2 pr-3">
                            <Button onClick={()=>{history.push('/app/edit_article')}}>Create new article</Button>
                        </div>
                        <div className="table-responsive animate__animated animate__fadeIn">
                        <table className="table align-items-center table-flush">
                            <thead className="thead-light">
                            <tr>
                                <th scope="col" className="sort" data-sort="name">Title</th>
                                <th scope="col" className="sort" data-sort="status">Scheduled Date</th>
                                <th scope="col">Status</th>
                                <th scope="col" >Actions</th>
                            </tr>
                            </thead>
                            <tbody className="list">
                            {loadArticles()}

                            </tbody>
                        </table>
                        </div>
                        {/* Card footer */}
                        <div className="card-footer py-4">
                        <nav aria-label="...">
                            <ul className="pagination justify-content-end mb-0">
                            <li className="page-item disabled">
                                <a className="page-link" href="#" tabIndex={-1}>
                                <i className="fas fa-angle-left" />
                                <span className="sr-only">Previous</span>
                                </a>
                            </li>
                            <li className="page-item active">
                                <a className="page-link" href="#">1</a>
                            </li>
                            <li className="page-item">
                                <a className="page-link" href="#">2 <span className="sr-only">(current)</span></a>
                            </li>
                            <li className="page-item"><a className="page-link" href="#">3</a></li>
                            <li className="page-item">
                                <a className="page-link" href="#">
                                <i className="fas fa-angle-right" />
                                <span className="sr-only">Next</span>
                                </a>
                            </li>
                            </ul>
                        </nav>
                        </div>
                    </div>
                    </div>
                </div>
                
                </div>
            </div>
        </div>
    );
    
}