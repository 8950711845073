import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import '../diigo/css/style.css';


export default function HomePage() {
    const history = useHistory();

    useEffect(() => {

    }, []);



    return (
        <div>
            <header>
                <div className="head_top">
                    <div className="header">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-3 logo_section">
                                    <div className="full">
                                        <div className="center-desk">
                                            <div className="logo">
                                                <a href="#"><img src="assets/img/home/logo.png" alt="#" /></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-9 nav_col">
                                    <nav className="navigation navbar navbar-expand-md navbar-dark ">
                                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExample04" aria-controls="navbarsExample04" aria-expanded="false" aria-label="Toggle navigation">
                                            <span className="navbar-toggler-icon"></span>
                                        </button>
                                        <div className="collapse navbar-collapse" id="navbarsExample04">
                                            <ul className="navbar-nav mr-auto">
                                                <li className="nav-item">
                                                    <a className="nav-link" href="#"> Home  </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link" href="#support">Donate</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link" href="#" onClick={() => { history.push(`/privacy`) }}>Privacy Policy</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="banner_main">
                        <div className="container-fluid">
                            <div className="row d_flex">
                                <div className="col-md-7">
                                    <div className="text-bg">
                                        <h1>Christian Prayer and Encouragement at your fingertips</h1>
                                        <p>'He Hears You' is a Christian-based app designed to help you by connecting you with Helpers to pray and speak with you.</p>
                                        <a href="https://play.google.com/store/apps/details?id=com.hearmejm.counsellingapp">Download For Android</a>
                                        <a href='https://apps.apple.com/app/he-hears-you-prayer-guide/id1662217151' className='second_btn'>Download For iOS</a>
                                    </div>
                                </div>
                                <div className="col-md-5 phone-img">
                                    <div className="text-img">
                                        <figure><img src="assets/img/home/apphome.png" alt="#" /></figure>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </header>

            <div id="book" className="business">
                <div className="container">
                    <h2 className='d_title1'>Connect with Helpers in 3 ways:</h2>
                    <div className="row">
                        <div className="col-md-4">
                            <h2 className='d_title'>Pray With Me</h2>
                            <p className='d_description'>Are you in need of prayer? We will connect you with a member of our Prayer team today.</p>
                            <br/>
                        </div>
                        <div className="col-md-4">
                            <h2 className='d_title'>Quick Chat</h2>
                            <p className='d_description'>Why wait when you can get instant help. Our Helpers are on stand-by waiting for your call to speak with you.</p>
                            <br/>
                        </div>
                        <div className="col-md-4">
                            <h2 className='d_title'>Book Appointment</h2>
                            <p className='d_description'>Book appoinment to speak with a Helper for Pastoral Counselling and Guidance at a time that is convenient for you.</p>
                            <br/>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section freely-communicate">
                <div className="container">
                    <h2 className='d_title1 txt-black'>Download the App:</h2>
                    <div className="row">
                        <div className="col-md-6 text-center">
                            <img className='phone_img' src="assets/img/home/android.png" alt="#" />
                            <a href='https://play.google.com/store/apps/details?id=com.hearmejm.counsellingapp' className='download_btn'>Download For Android</a>
                        </div>
                        <div className="col-md-6 text-center">
                            <img className='phone_img' src="assets/img/home/ios.png" alt="#" />
                            <a href='https://apps.apple.com/app/he-hears-you-prayer-guide/id1662217151' className='download_btn'>Download For iPhone/iPad</a>
                        </div>
                    </div>
                </div>
            </div>

            <div id="book" className="business">
                <div id='support' className="container">
                    <h2 className='d_title1'>Support the work:</h2>
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <p className='d_description'>This is a registered Christian charity set up to help callers with their problems through prayer and biblically based counsel. We are grateful for any donations towards this cause. Please see below how to donate.</p>
                            <a href='https://donate.stripe.com/5kA6rZ0CNep67du5kl' className='download_btn'>Donate here</a>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div id="contact" className="contact">
                <div className="container">
                    <div className="row">
                    <div className="col-md-12">
                        <div className="titlepage">
                            <h2>Contact us</h2>
                            <span>Do you have any questions please feel free to reach us</span>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                    <div className="col-md-12 ">
                        <form className="main_form ">
                            <div className="row">
                                <div className="col-md-12 ">
                                <input className="form_contril" placeholder="Name " type="text" name="Name "/>
                                </div>
                                <div className="col-md-12">
                                <input className="form_contril" placeholder="Phone Number" type="text" name=" Phone Number"/>
                                </div>
                                <div className="col-md-12">
                                <input className="form_contril" placeholder="Email" type="text" name="Email"/>
                                </div>
                                <div className="col-md-12">
                                <textarea className="textarea" placeholder="Message" type="text" name="Message"></textarea>
                                </div>
                                <div className="col-sm-12">
                                <button className="send_btn">Send</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    </div>
                </div>
            </div> */}
        </div>
    );

}