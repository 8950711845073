import React, {Component} from "react";
// import blue from "../assets/img/brand/blue.png";
import FootData from "../components/foot-data.component";
// import '../components/head.component';
import axios from "axios";
import { Link } from "react-router-dom";

export default class AdminLayout extends Component{
    constructor(props){
        super(props);
        this.logoutUser = this.logoutUser.bind(this);
    }

    logoutUser(e){

        axios.get(process.env.REACT_APP_BACKEND + '/users/logout', {
            withCredentials: true,
        })
        .then(response => {
        
            window.location = '/';
        });
    }

    render() {
        let icon_num = (this.props.user.icon) ? this.props.user.icon : 1;
        let icon = `/assets/img/profile_icons/1.png`;
        if (this.props.user.user_type === "CLIENT") {
            icon = `/assets/img/profile_icons/${icon_num}.png`;
        } else {
            icon = `/assets/img/counsellor_profile_icons/${icon_num}.png`;
        }
        return (
            <div>
                <nav className="sidenav navbar navbar-vertical  fixed-left  navbar-expand-xs navbar-light bg-white" id="sidenav-main">
                    <div className="scrollbar-inner">
                        <div className="sidenav-header  align-items-center">
                            <a className="navbar-brand" href="#">
                            <img src="/assets/img/brand/blue.png" className="navbar-brand-img" alt="..." />
                            </a>
                        </div>
                        <div className="navbar-inner">
                            
                            <div className="collapse navbar-collapse" id="sidenav-collapse-main">
                            
                            <ul className="navbar-nav">

                                <li className="nav-item">
                                <Link className={`nav-link ${this.props.tab === 'dashboard' && 'active'}`} to="/app">
                                    <i className="ni ni-tv-2 text-primary"></i>
                                    <span className="nav-link-text">Dashboard</span>
                                </Link>
                                </li>
                                {(()=>{
                                    var tabs = [];
                                    if(this.props.user.user_type === 'COUNSELLOR'){
                                        tabs.push(
                                            <li key="schedule" className="nav-item">
                                                <Link className={`nav-link ${this.props.tab === 'schedule' && 'active'}`} to="/app/schedule">
                                                    <i className="ni ni-planet text-orange"></i>
                                                    <span className="nav-link-text">My Schedule</span>
                                                </Link>
                                            </li>
                                        );
                                    }

                                    if(this.props.user.user_type === 'ADMIN'){
                                        tabs.push(<li key="calendar" className="nav-item">
                                                    <Link className={`nav-link ${this.props.tab === 'calendar' && 'active'}`}  to="/app/calendar">
                                                        <i className="ni ni-planet text-orange"></i>
                                                        <span className="nav-link-text">Calendar</span>
                                                    </Link>
                                                </li>)
                                    }

                                    if(this.props.user.user_type === 'ADMIN'){
                                        tabs.push(
                                                    <li key="counsellors" className="nav-item">
                                                        <Link className={`nav-link ${this.props.tab === 'counsellors' && 'active'}`}  to="/app/counsellors">
                                                            <i className="ni ni-pin-3 text-primary"></i>
                                                            <span className="nav-link-text">Helpers</span>
                                                        </Link>
                                                    </li>
                                                );
                                    }

                                    if(this.props.user.user_type === 'ADMIN'){
                                        tabs.push(
                                            <li key="clients" className="nav-item">
                                                <Link className={`nav-link ${this.props.tab === 'clients' && 'active'}`}  to="/app/clients">
                                                    <i className="ni ni-single-02 text-yellow"></i>
                                                    <span className="nav-link-text">Callers</span>
                                                </Link>
                                            </li>
                                        );
                                    }

                                    if(this.props.user.user_type === 'ADMIN' || this.props.user.user_type === 'COUNSELLOR'){
                                        tabs.push(
                                            <li key="appointments" className="nav-item">
                                                <Link className={`nav-link ${this.props.tab === 'appointments' && 'active'}`}  to="examples/tables.html">
                                                    <i className="ni ni-bullet-list-67 text-default"></i>
                                                    <span className="nav-link-text">Appointments</span>
                                                </Link>
                                            </li>
                                        );
                                    }

                                    if(this.props.user.user_type === 'COUNSELLOR'){
                                        tabs.push(
                                            <li key="clients" className="nav-item">
                                                <Link className={`nav-link ${this.props.tab === 'clients' && 'active'}`}  to="/app/clients">
                                                    <i className="ni ni-single-02 text-yellow"></i>
                                                    <span className="nav-link-text">Clients</span>
                                                </Link>
                                            </li>
                                        );
                                    }

                                    if(this.props.user.user_type === 'COUNSELLOR' || this.props.user.user_type === 'ADMIN'){
                                        tabs.push(<li key="chat_requests" className="nav-item">
                                                    <Link className={`nav-link ${this.props.tab === 'chat_requests' && 'active'}`}  to="/app/chat_requests">
                                                        <i className="ni ni-chat-round text-info"></i>
                                                        <span className="nav-link-text">Chat Requests</span>
                                                    </Link>
                                                </li>);
                                    }

                                    if(this.props.user.user_type === 'COUNSELLOR'){
                                        tabs.push(<li key="calendar" className="nav-item">
                                                    <Link className={`nav-link ${this.props.tab === 'calendar' && 'active'}`}  to="/app/calendar">
                                                        <i className="ni ni-calendar-grid-58 text-green"></i>
                                                        <span className="nav-link-text">Calendar</span>
                                                    </Link>
                                                </li>);
                                    }

                                    if(this.props.user.user_type === 'ADMIN' || this.props.user.user_type === 'COUNSELLOR'){
                                        tabs.push(
                                            <li key="questionnaire" className="nav-item">
                                                <Link className={`nav-link ${this.props.tab === 'reports' && 'active'}`}  to="/app/reports">
                                                    <i className="ni ni-key-25 text-info"></i>
                                                    <span className="nav-link-text">Reports</span>
                                                </Link>
                                            </li>
                                        );
                                    }

                                    if(this.props.user.user_type === 'ADMIN'){
                                        tabs.push(<li key="calendar" className="nav-item">
                                                    <Link className={`nav-link ${this.props.tab === 'articles' && 'active'}`}  to="/app/articles">
                                                        <i className="ni ni-planet text-orange"></i>
                                                        <span className="nav-link-text">Articles</span>
                                                    </Link>
                                                </li>)
                                        tabs.push(<li key="calendar" className="nav-item">
                                                <Link className={`nav-link ${this.props.tab === 'resources' && 'active'}`}  to="/app/resources">
                                                    <i className="ni ni-planet text-orange"></i>
                                                    <span className="nav-link-text">Resources</span>
                                                </Link>
                                            </li>)
                                        tabs.push(<li key="calendar" className="nav-item">
                                                <Link className={`nav-link ${this.props.tab === 'prayers' && 'active'}`}  to="/app/prayers">
                                                    <i className="ni ni-box-2 text-yello"></i>
                                                    <span className="nav-link-text">Prayers</span>
                                                </Link>
                                            </li>)
                                        tabs.push(<li key="calendar" className="nav-item">
                                            <Link className={`nav-link ${this.props.tab === 'consent_forms' && 'active'}`}  to="/app/consent_forms">
                                                <i className="ni ni-box-2 text-yello"></i>
                                                <span className="nav-link-text">Consent Forms</span>
                                            </Link>
                                        </li>)
                                        tabs.push(<li key="calendar" className="nav-item">
                                        <Link className={`nav-link ${this.props.tab === 'reminders' && 'active'}`}  to="/app/reminders">
                                            <i className="ni ni-album-2 text-default"></i>
                                            <span className="nav-link-text">Reminders</span>
                                        </Link>
                                    </li>)
                                        tabs.push(<li key="calendar" className="nav-item">
                                        <Link className={`nav-link ${this.props.tab === 'scriptures' && 'active'}`}  to="/app/scriptures">
                                            <i className="ni ni-album-2 text-default"></i>
                                            <span className="nav-link-text">Scriptures</span>
                                        </Link>
                                    </li>)
                                    }

                                    return tabs;
                                })()}
                                
                                
                            </ul>
                            
                            <hr className="my-3" />
                            
                            <h6 className="navbar-heading p-0 text-muted">
                                <span  className="docs-normal">Settings</span>
                            </h6>
                            
                            <ul className="navbar-nav mb-md-3">
                                <li className="nav-item">
                                <a className="nav-link" href='#' onClick={this.logoutUser} >
                                    <i className="ni ni-spaceship"></i>
                                    <span className="nav-link-text">Log Out</span>
                                </a>
                                </li>
                                
                            </ul>
                            </div>
                        </div>
                    </div>
                </nav>
                <div className="main-content" id="panel">
                
                    <nav className="navbar navbar-top navbar-expand navbar-dark bg-primary border-bottom">
                    <div className="container-fluid">
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        
                        <form className="navbar-search navbar-search-light form-inline mr-sm-3" id="navbar-search-main">
                            <div className="form-group mb-0">
                            <div className="input-group input-group-alternative input-group-merge">
                                <div className="input-group-prepend">
                                <span className="input-group-text"><i className="fas fa-search"></i></span>
                                </div>
                                <input className="form-control" placeholder="Search" type="text" />
                            </div>
                            </div>
                            <button type="button" className="close" data-action="search-close" data-target="#navbar-search-main" aria-label="Close">
                            <span aria-hidden="true">×</span>
                            </button>
                        </form>
                        
                        <ul className="navbar-nav align-items-center  ml-md-auto ">
                            <li className="nav-item d-xl-none">
                            
                            <div className="pr-3 sidenav-toggler sidenav-toggler-dark" data-action="sidenav-pin" data-target="#sidenav-main">
                                <div className="sidenav-toggler-inner">
                                <i className="sidenav-toggler-line"></i>
                                <i className="sidenav-toggler-line"></i>
                                <i className="sidenav-toggler-line"></i>
                                </div>
                            </div>
                            </li>
                            <li className="nav-item d-sm-none">
                            <a className="nav-link" href="#" data-action="search-show" data-target="#navbar-search-main">
                                <i className="ni ni-zoom-split-in"></i>
                            </a>
                            </li>
                            <li className="nav-item dropdown">
                            <a className="nav-link" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="ni ni-bell-55"></i>
                            </a>
                            <div className="dropdown-menu dropdown-menu-xl  dropdown-menu-right  py-0 overflow-hidden">
                                
                                <div className="px-3 py-3">
                                <h6 className="text-sm text-muted m-0">You have <strong className="text-primary">13</strong> notifications.</h6>
                                </div>
                                
                                <div className="list-group list-group-flush">
                                <a href="#!" className="list-group-item list-group-item-action">
                                    <div className="row align-items-center">
                                    <div className="col-auto">
                                        
                                        <img alt="Image placeholder" src="/assets/img/theme/team-1.jpg" className="avatar rounded-circle" />
                                    </div>
                                    <div className="col ml--2">
                                        <div className="d-flex justify-content-between align-items-center">
                                        <div>
                                            <h4 className="mb-0 text-sm">{this.props.user.first_name}</h4>
                                        </div>
                                        <div className="text-right text-muted">
                                            <small>2 hrs ago</small>
                                        </div>
                                        </div>
                                        <p className="text-sm mb-0">Let's meet at Starbucks at 11:30. Wdyt?</p>
                                    </div>
                                    </div>
                                </a>
                                <a href="#!" className="list-group-item list-group-item-action">
                                    <div className="row align-items-center">
                                    <div className="col-auto">
                                        
                                        <img alt="Image placeholder" src="/assets/img/theme/team-2.jpg" className="avatar rounded-circle" />
                                    </div>
                                    <div className="col ml--2">
                                        <div className="d-flex justify-content-between align-items-center">
                                        <div>
                                            <h4 className="mb-0 text-sm">John Snow</h4>
                                        </div>
                                        <div className="text-right text-muted">
                                            <small>3 hrs ago</small>
                                        </div>
                                        </div>
                                        <p className="text-sm mb-0">A new issue has been reported for Argon.</p>
                                    </div>
                                    </div>
                                </a>
                                <a href="#!" className="list-group-item list-group-item-action">
                                    <div className="row align-items-center">
                                    <div className="col-auto">
                                        
                                        <img alt="Image placeholder" src="/assets/img/theme/team-3.jpg" className="avatar rounded-circle" />
                                    </div>
                                    <div className="col ml--2">
                                        <div className="d-flex justify-content-between align-items-center">
                                        <div>
                                            <h4 className="mb-0 text-sm">John Snow</h4>
                                        </div>
                                        <div className="text-right text-muted">
                                            <small>5 hrs ago</small>
                                        </div>
                                        </div>
                                        <p className="text-sm mb-0">Your posts have been liked a lot.</p>
                                    </div>
                                    </div>
                                </a>
                                <a href="#!" className="list-group-item list-group-item-action">
                                    <div className="row align-items-center">
                                    <div className="col-auto">
                                        
                                        <img alt="Image placeholder" src="/assets/img/theme/team-4.jpg" className="avatar rounded-circle" />
                                    </div>
                                    <div className="col ml--2">
                                        <div className="d-flex justify-content-between align-items-center">
                                        <div>
                                            <h4 className="mb-0 text-sm">John Snow</h4>
                                        </div>
                                        <div className="text-right text-muted">
                                            <small>2 hrs ago</small>
                                        </div>
                                        </div>
                                        <p className="text-sm mb-0">Let's meet at Starbucks at 11:30. Wdyt?</p>
                                    </div>
                                    </div>
                                </a>
                                <a href="#!" className="list-group-item list-group-item-action">
                                    <div className="row align-items-center">
                                    <div className="col-auto">
                                        
                                        <img alt="Image placeholder" src="/assets/img/theme/team-5.jpg" className="avatar rounded-circle" />
                                    </div>
                                    <div className="col ml--2">
                                        <div className="d-flex justify-content-between align-items-center">
                                        <div>
                                            <h4 className="mb-0 text-sm">John Snow</h4>
                                        </div>
                                        <div className="text-right text-muted">
                                            <small>3 hrs ago</small>
                                        </div>
                                        </div>
                                        <p className="text-sm mb-0">A new issue has been reported for Argon.</p>
                                    </div>
                                    </div>
                                </a>
                                </div>
                                
                                <a href="#!" className="dropdown-item text-center text-primary font-weight-bold py-3">View all</a>
                            </div>
                            </li>
                            <li className="nav-item dropdown">
                            <a className="nav-link" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="ni ni-ungroup"></i>
                            </a>
                            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-dark bg-default  dropdown-menu-right ">
                                <div className="row shortcuts px-4">
                                <a href="#!" className="col-4 shortcut-item">
                                    <span className="shortcut-media avatar rounded-circle bg-gradient-red">
                                    <i className="ni ni-calendar-grid-58"></i>
                                    </span>
                                    <small>Calendar</small>
                                </a>
                                <a href="#!" className="col-4 shortcut-item">
                                    <span className="shortcut-media avatar rounded-circle bg-gradient-orange">
                                    <i className="ni ni-email-83"></i>
                                    </span>
                                    <small>Email</small>
                                </a>
                                <a href="#!" className="col-4 shortcut-item">
                                    <span className="shortcut-media avatar rounded-circle bg-gradient-info">
                                    <i className="ni ni-credit-card"></i>
                                    </span>
                                    <small>Payments</small>
                                </a>
                                <a href="#!" className="col-4 shortcut-item">
                                    <span className="shortcut-media avatar rounded-circle bg-gradient-green">
                                    <i className="ni ni-books"></i>
                                    </span>
                                    <small>Reports</small>
                                </a>
                                <a href="#!" className="col-4 shortcut-item">
                                    <span className="shortcut-media avatar rounded-circle bg-gradient-purple">
                                    <i className="ni ni-pin-3"></i>
                                    </span>
                                    <small>Maps</small>
                                </a>
                                <a href="#!" className="col-4 shortcut-item">
                                    <span className="shortcut-media avatar rounded-circle bg-gradient-yellow">
                                    <i className="ni ni-basket"></i>
                                    </span>
                                    <small>Shop</small>
                                </a>
                                </div>
                            </div>
                            </li>
                        </ul>
                        <ul className="navbar-nav align-items-center  ml-auto ml-md-0 ">
                            <li className="nav-item dropdown">
                            <a className="nav-link pr-0" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <div className="media align-items-center">
                                <span className="avatar avatar-sm rounded-circle">
                                    <img alt="Image placeholder" src={icon} />
                                </span>
                                <div className="media-body  ml-2  d-none d-lg-block">
                                    <span className="mb-0 text-sm  font-weight-bold">{this.props.user.first_name + ' ' + this.props.user.last_name}</span>
                                </div>
                                </div>
                            </a>
                            <div className="dropdown-menu  dropdown-menu-right ">
                                <div className="dropdown-header noti-title">
                                <h6 className="text-overflow m-0">Welcome!</h6>
                                </div>
                                <a href="#!" className="dropdown-item">
                                <i className="ni ni-single-02"></i>
                                <span>My profile</span>
                                </a>
                                <a href="#!" className="dropdown-item">
                                <i className="ni ni-settings-gear-65"></i>
                                <span>Settings</span>
                                </a>
                                <a href="#!" className="dropdown-item">
                                <i className="ni ni-calendar-grid-58"></i>
                                <span>Activity</span>
                                </a>
                                <a href="#!" className="dropdown-item">
                                <i className="ni ni-support-16"></i>
                                <span>Support</span>
                                </a>
                                <div className="dropdown-divider"></div>
                                <a href='#' onClick={this.logoutUser} className="dropdown-item">
                                <i className="ni ni-user-run"></i>
                                <span>Logout</span>
                                </a>
                            </div>
                            </li>
                        </ul>
                        </div>
                    </div>
                    </nav>
                    {this.props.page}
                </div>
                <FootData/>
            </div>
        )
    }
}