import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import {useParams} from "react-router-dom";
import 'suneditor/dist/css/suneditor.min.css';


export default function ShowConsentFormPage() {
    const [title, setTitle] = useState(null);
    const [body, setBody] = useState(null);
    const tags = useRef([]);
    const [refresh, setRefresh] = useState(0);
    const {id} = useParams();

    useEffect(() => {
        if(id){
            axios.get(process.env.REACT_APP_BACKEND + `/consent_form/get_consent_form/${id}`, {
                withCredentials: true
            }).then(response => {
                let consentForm = response.data;
                setTitle(consentForm.title);
                let _body = atob(consentForm.body);
                setBody(_body);
                setRefresh(refresh + 1);
                console.log(consentForm);
            })
            .catch(error => {
                console.log(error);
            })
        }
        
    }, []);

    
    

    return (
        <div className='show-consentForm-main'>
            { <div dangerouslySetInnerHTML={{ __html: body }} /> }
        </div>
    );
    
}