import React, {Component} from "react";
import axios from "axios";

export default class ClientPage extends Component {
    constructor(props){
        super(props);
        this.loadUsers = this.loadUsers.bind(this);
        this.UserItem = this.UserItem.bind(this);
        this.state = {
            users: []
        }
    }

    componentDidMount(){
        axios.get(process.env.REACT_APP_BACKEND + '/users/getUsers', {
            withCredentials: true
        }).then(response => {
            console.log(response.data);
            // var users = this.state.users;
            // users.push(response)
            this.setState({
                users: response.data
            })
        })
        .catch(error => {
            console.log(error.response.data);
        })
    }

    UserItem(props){
        
        return (
            <tr>
                <th scope="row">
                    <div className="media align-items-center">
                        <a href="#" className="avatar rounded-circle mr-3">
                            <img alt="Image placeholder" src="/assets/img/theme/bootstrap.jpg" />
                        </a>
                        <div className="media-body">
                            <span className="name mb-0 text-sm">{props.user.username}</span>
                        </div>
                    </div>
                </th>
                <td>
                    {props.user.first_name}
                </td>
                <td>
                    {props.user.last_name}
                </td>
                <td>
                    {props.user.createdAt}
                </td>
                <td>
                <div className="d-flex align-items-center">
                    <span className="completion mr-2">0%</span>
                    <div>
                        <div className="progress">
                            <div className="progress-bar bg-warning" role="progressbar" aria-valuenow={1} aria-valuemin={0} aria-valuemax={100} style={{width: '1%'}} />
                        </div>
                    </div>
                </div>
                </td>
                <td className="text-right">
                
                </td>
            </tr>
        );
    }

    loadUsers(){
        if(this.state.users.length > 0){
            return this.state.users.map((user) => {
                return <this.UserItem user={user} />
            });
        }else{
            return (
                <tr>
                    <td colspan='6'><div className='alert alert-info text-center m-2'>No Users found</div></td>
                    
                </tr>
            
            );
        }
    }

    render() {
        return (
            <div>
                <div>
                    <div className="header bg-primary pb-6">
                    <div className="container-fluid">
                        <div className="header-body">
                        <div className="row align-items-center py-4">
                            <div className="col-lg-6 col-7">
                            <h6 className="h2 text-white d-inline-block mb-0">Callers</h6>
                            <nav aria-label="breadcrumb" className="d-none d-md-inline-block ml-md-4">
                                <ol className="breadcrumb breadcrumb-links breadcrumb-dark">
                                <li className="breadcrumb-item"><a href="/app"><i className="fas fa-home" /></a></li>
                                <li className="breadcrumb-item"><a href="/app">Dashboard</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Callers</li>
                                </ol>
                            </nav>
                            </div>
                            <div className="col-lg-6 col-5 text-right">
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    {/* Page content */}
                    <div className="container-fluid mt--6">
                    <div className="row">
                        <div className="col">
                        <div className="card">
                            {/* Card header */}
                            <div className="card-header border-0">
                            <h3 className="mb-0">All Callers</h3>
                            </div>
                            {/* Light table */}
                            <div className="table-responsive animate__animated animate__fadeIn">
                            <table className="table align-items-center table-flush">
                                <thead className="thead-light">
                                <tr>
                                    <th scope="col" className="sort" data-sort="name">Username</th>
                                    <th scope="col" className="sort" data-sort="budget">Firstname</th>
                                    <th scope="col" className="sort" data-sort="status">Lastname</th>
                                    <th scope="col">Registered</th>
                                    <th scope="col" className="sort" data-sort="completion">Questionnaire Completion</th>
                                    <th scope="col" >Actions</th>
                                </tr>
                                </thead>
                                <tbody className="list">
                                {this.loadUsers()}

                                </tbody>
                            </table>
                            </div>
                            {/* Card footer */}
                            <div className="card-footer py-4">
                            <nav aria-label="...">
                                <ul className="pagination justify-content-end mb-0">
                                <li className="page-item disabled">
                                    <a className="page-link" href="#" tabIndex={-1}>
                                    <i className="fas fa-angle-left" />
                                    <span className="sr-only">Previous</span>
                                    </a>
                                </li>
                                <li className="page-item active">
                                    <a className="page-link" href="#">1</a>
                                </li>
                                <li className="page-item">
                                    <a className="page-link" href="#">2 <span className="sr-only">(current)</span></a>
                                </li>
                                <li className="page-item"><a className="page-link" href="#">3</a></li>
                                <li className="page-item">
                                    <a className="page-link" href="#">
                                    <i className="fas fa-angle-right" />
                                    <span className="sr-only">Next</span>
                                    </a>
                                </li>
                                </ul>
                            </nav>
                            </div>
                        </div>
                        </div>
                    </div>
                    
                    </div>
                </div>
            </div>
        );
    }
}