import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import {useParams} from "react-router-dom";
import 'suneditor/dist/css/suneditor.min.css';
import SunEditor, { buttonList } from 'suneditor-react';



export default function ShowArticlePage() {
    const [title, setTitle] = useState(null);
    const [description, setDescription] = useState(null);
    const [scheduledDate, setScheduledDate] = useState(null);
    const tags = useRef([]);
    const [refresh, setRefresh] = useState(0);
    const {id} = useParams();

    useEffect(() => {
        if(id){
            axios.get(process.env.REACT_APP_BACKEND + `/article/get_article/${id}`, {
                withCredentials: true
            }).then(response => {
                let article = response.data;
                setTitle(article.title);
                let _description = atob(article.description);
                setDescription(_description);
                setScheduledDate(article.scheduledDate);
                if(article.tags){
                    tags.current = article.tags;
                }
                setRefresh(refresh + 1);
                console.log(article);
            })
            .catch(error => {
                console.log(error);
            })
        }
        
    }, []);

    
    

    return (
        <div className='show-article-main'>
            <SunEditor disable={true} enableToolbar={false} showToolbar={false} setContents={description} width="100%" height="100%" setOptions={{ resizingBar: false, showPathLabel: false }} />
        </div>
    );
    
}