import React, {Component} from "react";
import axios from "axios";
import Form from "react-bootstrap/Form";
import {Modal, Button, Alert} from "react-bootstrap";

export default class SchedulePage extends Component {
    constructor(props){
        super(props);
        this.TimeModal = this.TimeModal.bind(this);
        this.RecurringBlock = this.RecurringBlock.bind(this);
        this.TimeModal = this.TimeModal.bind(this);
        this.changeBlock = this.changeBlock.bind(this);
        this.displayModal = this.displayModal.bind(this);
        this.hideModal = this.hideModal.bind(this);

        this.selectDay = this.selectDay.bind(this);
        this.getTimes = this.getTimes.bind(this);
        this.onStartTimeSelect = this.onStartTimeSelect.bind(this);
        this.onEndTimeSelect = this.onEndTimeSelect.bind(this);
        this.onTimeBoxClicked = this.onTimeBoxClicked.bind(this);
        this.TimeBox = this.TimeBox.bind(this);
        this.SaveOptionsBox = this.SaveOptionsBox.bind(this);
        this.onClickCancelSave = this.onClickCancelSave.bind(this);
        this.onClickSave = this.onClickSave.bind(this);

        this.state = {
            scheduleType: 'RECURRING',
            displayModal: false,
            monday_selected: false,
            tuesday_selected: false,
            wednesday_selected: false,
            thursday_selected: false,
            friday_selected: false,
            saturday_selected: false,
            sunday_selected: false,

            monday_start_time: 8,
            monday_end_time: 9,
            tuesday_start_time: 8,
            tuesday_end_time: 9,
            wednesday_start_time: 8,
            wednesday_end_time: 9,
            thursday_start_time: 8,
            thurday_end_time: 9,
            friday_start_time: 8,
            friday_end_time: 9,
            saturday_start_time: 8,
            saturday_end_time: 9,
            sunday_start_time: 8,
            sunday_end_time: 9,

            change_made: false
        }
    }

    componentDidMount(){
        axios({
            url: process.env.REACT_APP_BACKEND + '/users/get_schedule',
            method: 'GET',
            withCredentials: true,
        }).then(response =>{
            if(response.data !== null){
                let schedule = response.data;
                if(schedule.time_slot !== null && schedule.time_slot.length > 0){
                    let selected_days = {};
                    schedule.time_slot.forEach(slot=>{
                        selected_days[slot.day + '_selected'] = true;
                        selected_days[slot.day + '_start_time'] = slot.start_time;
                        selected_days[slot.day + '_end_time'] = slot.end_time; 
                    });
                    this.setState(selected_days);
                    console.log(selected_days);
                }
                // console.log(schedule.time_slot);
            }
        }).catch(error => {
            console.log(error.response.data);
        });
    }

    selectDay(e){
        var {name, checked} = e.target;
        this.setState({
            [name]: checked,
            change_made: true
        });
    }

    onTimeBoxClicked(e, name){
        this.setState({
            [name + '_selected']: !this.state[name + '_selected'],
            change_made: true
        });
        
    }

    getTimes(day, isEndTime = false){
        var available_times = [
            {time: 8, label: '8 A.M'},
            {time: 9, label: '9 A.M'},
            {time: 10, label: '10 A.M'},
            {time: 11, label: '11 A.M'},
            {time: 12, label: '12 P.M'},
            {time: 13, label: '1 P.M'},
            {time: 14, label: '2 P.M'},
            {time: 15, label: '3 P.M'},
            {time: 16, label: '4 P.M'},
            {time: 17, label: '5 P.M'},
            {time: 18, label: '6 P.M'},
            {time: 19, label: '7 P.M'},
            {time: 20, label: '8 P.M'},
            {time: 21, label: '9 P.M'},
            {time: 22, label: '10 P.M'}
        ];

        var selected_start_time = this.state[day+ '_start_time'];
       
        if(!isEndTime){
            var start_time_options = [];
            start_time_options = available_times.map((available_time, idx)=>{
                if(idx !== (available_times.length - 1)){
                    return (
                        <option  key={available_time.time} value={available_time.time}>{available_time.label}</option>
                    );
                }
                return null;
            });
            return start_time_options;
        }else{
            var selected_end_time = this.state[day + '_end_time'];
            var end_time_options = [];
            end_time_options = available_times.map((available_time, idx)=>{
                if(available_time.time > selected_start_time){
                    return (
                        <option  key={available_time.time} value={available_time.time} >{available_time.label}</option>
                    );
                }
                return null;
            });
            return end_time_options;
        }
        
    }

    onStartTimeSelect(e){
        let new_start_time = Number(e.target.value);
        let end_time = Number(this.state[e.target.name + '_end_time']);
        let increment_end_time = (new_start_time > end_time) ? new_start_time + 1 : end_time;
        
        this.setState({
            [e.target.name + '_start_time']: new_start_time,
            [e.target.name + '_end_time']: increment_end_time,
            change_made: true
        });
        e.stopPropagation()
    }

    onEndTimeSelect(e){
        this.setState({
            [e.target.name + '_end_time']: e.target.value,
            change_made: true
        });
        // console.log(e.target.name);
    }

    onClickCancelSave(e){
        this.setState({
            change_made: false
        });
    }

    onClickSave(e){
        let days = [
            'monday',
            'tuesday',
            'wednesday',
            'thursday',
            'friday',
            'saturday',
            'sunday'
        ];

        let selected_days = [];

        days.forEach( day =>{
            if(this.state[day + '_selected']){
                selected_days.push({
                    day: day,
                    start_time: this.state[day + '_start_time'],
                    end_time: this.state[day + '_end_time'],
                });
            }
        });

        axios({
            url: process.env.REACT_APP_BACKEND + '/schedule/add',
            method: 'POST',
            withCredentials: true,
            data: selected_days
        }).then(response =>{
            this.props.showMessage("Scheduled updated successfully");
            this.setState({
                change_made: false
            });
            // console.log(response.data);
        }).catch(error =>{
            this.props.showMessage("An error ocurred, please contact your administrator", false);
            console.log(error.response.data);
        });


    }

    SaveOptionsBox(props){
        var expanded = props.bottomOptions? true : this.state.change_made;
        return (
            <Alert className={`save-options ${expanded ? 'expanded' : 'collapsed'}`}>
                <label>Do you want to save your changes now? </label>
                <Button onClick={this.onClickCancelSave} className='ml-3' variant='secondary'>Cancel</Button>
                <Button onClick={this.onClickSave} variant='primary'>Save</Button>
            </Alert>
        );
    }
    
    TimeBox(props){
        const day = props.day;
        return (
            <Alert onClick={(e) => this.onTimeBoxClicked(e, day)} className='day-block' variant={this.state[day + '_selected'] ? 'success' : 'danger'}>
                    <div className='row mr-0'>
                        <Form.Group className="col-sm-3  mt-2 mb-2">
                            <Form.Check 
                                className="regular-checkbox" 
                                name={`${day}_selected`} 
                                onChange={this.selectDay}
                                checked={this.state[day + '_selected'] ? true : false}
                                label={`Every ${day} at:`}
                            />
                        </Form.Group>
                        <Form.Group className="col-sm-4">
                            <Form.Control 
                                onChange={this.onStartTimeSelect} 
                                onClick = {(e)=>{e.stopPropagation();}}
                                as="select" 
                                name={day}
                                id={`start-time-${day}-input`} 
                                className='time-select' 
                                disabled={this.state[day + '_selected'] ? false: true}
                                value={this.state[day + '_start_time']}
                            >
                                {this.getTimes(day)}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group className="col-sm-1 mt-2">
                            <Form.Label>to</Form.Label>
                        </Form.Group>
                        <Form.Group className="col-sm-4">
                            <Form.Control 
                                onChange={this.onEndTimeSelect} 
                                as="select" 
                                name={day} 
                                id={`end-time-${day}-input`} 
                                className='time-select' 
                                onClick = {(e)=>{e.stopPropagation();}}
                                value={this.state[day + '_end_time']}
                                disabled={this.state[day + '_selected'] ? false: true}
                            >
                                {this.getTimes(day, true)}
                            </Form.Control>
                        </Form.Group>
                    </div>

                </Alert>
        );
    }

    RecurringBlock(props){
        return (
            <div className='recurring_block'>
                <this.SaveOptionsBox />
                <this.TimeBox day='monday' />
                <this.TimeBox day='tuesday' />
                <this.TimeBox day='wednesday' />
                <this.TimeBox day='thursday' />
                <this.TimeBox day='friday' />
                <this.TimeBox day='saturday' />
                <this.TimeBox day='sunday' />
                <this.SaveOptionsBox bottomOptions={true} />
            </div>
        );
    }

    FixedBlock(props){
        return (
            <div id='fixed_block'>
                 <Form.Group controlId="fixed_date">
                    <Form.Label>Select a Date</Form.Label>
                    <Form.Control type="date" name="fixed_date" placeholder="22/04/2020" />
                </Form.Group>
                <div className='row mr-0'>
                    <Form.Group className="col-sm-6">
                        <Form.Label>Start Time</Form.Label>
                        <Form.Control type="time" id="start-time-local-input" />
                    </Form.Group>
                    <Form.Group controlId='end-time-local' className="col-sm-6">
                        <Form.Label>End Time</Form.Label>
                        <Form.Control type="time" id="end-time-local-input" />
                    </Form.Group>
                </div>
            </div>
        );
    }

    changeBlock(e){
        if(e.target.value === 'FIXED'){
            this.setState({
                scheduleType: 'FIXED'
            });
        }else{
            this.setState({
                scheduleType: 'RECURRING'
            });
        }
    }

    displayModal(e){
        this.setState({
            displayModal: true
        });
    }

    hideModal(e){
        this.setState({
            displayModal: false
        });
    }

    TimeModal(props){
        return (
            <Modal show={props.show} onHide={this.hideModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Create Schedule</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className='form'>
                        <div className='form-group'>
                            <label className='form-control-label'>Select a Schedule Type</label>
                            <select onChange={this.changeBlock} className='form-control' name='schedule-type'>
                                <option disabled='disabled'>Select a schedule type</option>
                                <option value='FIXED' selected={this.state.scheduleType === 'FIXED' ? 'selected' : false}>Fixed</option>
                                <option value='RECURRING' selected={this.state.scheduleType === 'RECURRING' ? 'selected' : false}>Recurring</option>
                            </select>
                        </div>
                        {this.state.scheduleType === 'RECURRING' ? <this.RecurringBlock /> : <this.FixedBlock />}
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.hideModal}>
                        Close
                    </Button>
                    <Button variant="primary">
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }


    render() {
        return (
            <div>
                <div>
                    <div className="header bg-primary pb-6">
                    <div className="container-fluid">
                        <div className="header-body">
                        <div className="row align-items-center py-4">
                            <div className="col-lg-6 col-7">
                            <h6 className="h2 text-white d-inline-block mb-0">My Schedule</h6>
                            <nav aria-label="breadcrumb" className="d-none d-md-inline-block ml-md-4">
                                <ol className="breadcrumb breadcrumb-links breadcrumb-dark">
                                <li className="breadcrumb-item"><a href="/app"><i className="fas fa-home" /></a></li>
                                <li className="breadcrumb-item"><a href="/app">Dashboard</a></li>
                                <li className="breadcrumb-item active" aria-current="page">My Schedule</li>
                                </ol>
                            </nav>
                            </div>
                            <div className="col-lg-6 col-5 text-right">
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    {/* Page content */}
                    <div className="container-fluid mt--6">
                    <div className="row">
                        <div className="col">
                            <div className="card schedule-container-main">
                                <div className='card-header schedule-container-head'>
                                    When are you available for bookings?
                                </div>
                                <div className='card-body schedule-container'>
                                    <this.RecurringBlock />
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    
                    </div>
                </div>
                
                <this.TimeModal show={this.state.displayModal} />
            </div>
            
        );
    }
}