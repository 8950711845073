import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import {Button, Row, Col, Form} from "react-bootstrap";
import {useHistory, useParams} from "react-router-dom";
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';


export default function EditConsentFormPage({launchChat, showMessage}) {
    const [title, setTitle] = useState(null);
    const [body, setBody] = useState(null);
    const [counsellor_id, setCounsellorId] = useState(null);
    const [counsellors, setCounsellors] = useState([]);
    const [numSummaryPoints, setNumSummaryPoints] = useState(1);
    const tags = useRef([]);
    const summary_points = useRef(['']);
    const [refresh, setRefresh] = useState(0);
    const history = useHistory();
    const {id} = useParams();

    useEffect(() => {
        if(id){
            axios.get(process.env.REACT_APP_BACKEND + `/consent_form/get_consent_form/${id}`, {
                withCredentials: true
            }).then(response => {
                let consentForm = response.data;
                let _body = atob(consentForm.body);
                setTitle(consentForm.title);
                setBody(_body);
                setCounsellorId(consentForm.counsellor_id);
                if(consentForm.summary_points){
                    summary_points.current = consentForm.summary_points;
                    setNumSummaryPoints(consentForm.summary_points.length)
                }
                setRefresh(refresh + 1);
                console.log(consentForm);
            })
            .catch(error => {
                console.log(error.response.data);
            })
        }

        getCounsellors();
        
    }, []);

    function getCounsellors(){
        axios.get(process.env.REACT_APP_BACKEND + '/users/get_counsellors', {
            withCredentials: true
        }).then(response => {
            console.log(response.data);
            let _counsellors = response.data.filter(c=>c.is_prayer_intercessor !== true);
            setCounsellors(_counsellors);
        })
        .catch(error => {
            console.log(error.response.data);
        })
    }
    function onFormSubmit(){
        let consentForm = {
            title,
            body: Buffer.from(body).toString('base64'),
            summary_points: summary_points.current.filter(e=>e !== null && e!== ''),
            counsellor_id
        }

        console.log(consentForm);

        if(!id){
            axios({
                url: process.env.REACT_APP_BACKEND + '/consent_form/create',
                method: 'POST',
                data: consentForm,
                withCredentials: true
            }).then(response => {
                showMessage('The Consent Form was created successfully');
                history.push('/app/consent_forms');
            }).catch(error => {
                showMessage(error.response.data.message, false);
            })
        }else{
            axios({
                url: process.env.REACT_APP_BACKEND + '/consent_form/update/' + id,
                method: 'POST',
                data: consentForm,
                withCredentials: true
            }).then(response => {
                showMessage('The consentForm was updated successfully');
                history.push('/app/consent_forms');
            }).catch(error => {
                showMessage(error.response.data.message, false);
            })
        }
        
    }

    function loadSummaryPoints(){
        let summaryPointComponents = [];
        for (let i=0; i<= numSummaryPoints; i++){
            summaryPointComponents.push(
            <Form.Control onChange={
                e=>{
                    summary_points.current[i] = e.target.value; 
                    if(numSummaryPoints == i){
                        setNumSummaryPoints(n=>n+1)
                    }
                }} type="text" placeholder="e.g Summary Point" defaultValue={summary_points.current[i]} />
            )
        }

        return summaryPointComponents;
    }

    function loadCounsellors(){
        let counsellor_components = [];
        counsellors.forEach(counsellor => {
            counsellor_components.push(<option value={counsellor._id}>{counsellor.first_name} {counsellor.last_name}</option>)
        });
        return counsellor_components;
    }

    return (
        <div>
            <div>
                <div className="header bg-primary pb-6">
                <div className="container-fluid">
                    <div className="header-body">
                    <div className="row align-items-center py-4">
                        <div className="col-lg-6 col-7">
                        <h6 className="h2 text-white d-inline-block mb-0">Edit Consent Form</h6>
                        <nav aria-label="breadcrumb" className="d-none d-md-inline-block ml-md-4">
                        <ol className="breadcrumb breadcrumb-links breadcrumb-dark">
                            <li className="breadcrumb-item"><a href="/app"><i className="fas fa-home" /></a></li>
                            <li className="breadcrumb-item"><a onClick={()=> {history.push("/app/dashboard")}}>Dashboard</a></li>
                            <li className="breadcrumb-item"><a onClick={()=> {history.push("/app/consentForms")}}>Consent Forms</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Create Consent Form</li>
                        </ol>
                        </nav>
                        </div>
                        <div className="col-lg-6 col-5 text-right">
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                {/* Page content */}
                <div className="container-fluid mt--6">
                <div className="row">
                    <div className="col">
                    <div className="card">
                        {/* Card header */}
                        <div className="card-header border-0">
                        <h3 className="mb-0">Edit Consent Form</h3>
                        </div>
                        {/* Light table */}
                        <div className="card-body">
                            <Form.Group>
                                <Form.Label>Title</Form.Label>
                                <Form.Control onChange={e=>setTitle(e.target.value)} type="text" placeholder="e.g Consent Form Title" value={title} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Body</Form.Label>
                            </Form.Group>
                            <SunEditor onChange={(content)=>setBody(content)} height={300} setContents={body} />
                            <br/>
                            <Form.Group r={refresh}>
                                <Form.Label>Summary Points</Form.Label>
                                {loadSummaryPoints()}
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Counsellor</Form.Label>
                                <Form.Control as="select" onChange={e=>setCounsellorId(e.target.value) } value={counsellor_id}>
                                    {loadCounsellors()}
                                </Form.Control>
                            </Form.Group>
                            
                            
                        </div>
                        {/* Card footer */}
                        <div className="card-footer py-4">
                            <Button onClick={onFormSubmit}>Save</Button>
                        </div>
                    </div>
                    </div>
                </div>
                
                </div>
            </div>
        </div>
    );
    
}