import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import {useParams} from "react-router-dom";
import 'suneditor/dist/css/suneditor.min.css';
import SunEditor, { buttonList } from 'suneditor-react';


export default function ShowPrayerPage() {
    const [title, setTitle] = useState(null);
    const [description, setDescription] = useState(null);
    const [scheduledDate, setScheduledDate] = useState(null);
    const tags = useRef([]);
    const [refresh, setRefresh] = useState(0);
    const {id} = useParams();

    useEffect(() => {
        if(id){
            axios.get(process.env.REACT_APP_BACKEND + `/prayer/get_prayer/${id}`, {
                withCredentials: true
            }).then(response => {
                let prayer = response.data;
                setTitle(prayer.title);
                let _description = atob(prayer.description);
                setDescription(_description);
                setScheduledDate(prayer.scheduledDate);
                if(prayer.tags){
                    tags.current = prayer.tags;
                }
                setRefresh(refresh + 1);
                console.log(prayer);
            })
            .catch(error => {
                console.log(error);
            })
        }
        
    }, []);

    
    

    return (
        <div className='show-prayer-main'>
                <SunEditor disable={true} enableToolbar={false} showToolbar={false} setContents={description} width="100%" height="100%" setOptions={{ resizingBar: false, showPathLabel: false }} />
        </div>
    );
    
}