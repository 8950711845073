import React, {Component} from "react";
import axios from "axios";
import {Row, Col, Button} from "react-bootstrap";
import Tada from "react-reveal/Tada";
import Reveal from "react-reveal/Reveal";

export default class CalendarPage extends Component {
    constructor(props){
        super(props);
        this.getDaysInMonth = this.getDaysInMonth.bind(this);
        this.AppointmentCalendar = this.AppointmentCalendar.bind(this);
        this.onNextMonthClick = this.onNextMonthClick.bind(this);
        this.onPreviousMonthClick = this.onPreviousMonthClick.bind(this);
        this.DayView = this.DayView.bind(this);
        this.HourView = this.HourView.bind(this);
        this.getAvailableAppointmentDays = this.getAvailableAppointmentDays.bind(this);
        this.onDaySelected = this.onDaySelected.bind(this);
        this.onDayBackClicked = this.onDayBackClicked.bind(this);

        this.state = {
            avalable_months: null,
            selected_month: null,
            available_appointment_days: [],
            appointment_dates_loaded: false,
            selected_day: null,
            available_counsellors: []
        }
    }

    componentDidMount(){
        let _date = new Date();
        let currentYear = _date.getFullYear();
        let currentMonth = _date.getMonth() + 1;
        let firstDate = currentMonth + '/1/' + currentYear;
        let available_months = [];
        let dt = new Date(firstDate);
        available_months.push({
            date: dt.toLocaleDateString(),
            starting_slot: dt.getDay() + 1,
            num_days: this.getDaysInMonth(dt.getMonth(), dt.getFullYear()),
            month_name: dt.toLocaleString('default', { month: 'long'}) + ' ' + dt.getFullYear(),
            year: dt.getFullYear(),
            month: dt.getMonth()
        });
        
        for(let i=0; i<4; i++){
            dt.setMonth(dt.getMonth() + 1);
            available_months.push({
                date: dt.toLocaleDateString(),
                starting_slot: dt.getDay() + 1,
                num_days: this.getDaysInMonth(dt.getMonth(), dt.getFullYear()),
                month_name: dt.toLocaleString('default', { month: 'long'}) + ' ' + dt.getFullYear(),
                year: dt.getFullYear(),
                month: dt.getMonth()
            });
        }
        // console.log(available_months[0]);
        this.setState({
            available_months: available_months,
            selected_month: available_months[0]
        });
    }

    onNextMonthClick(e){
        for(let i = 0; i < this.state.available_months.length - 1; i++){
            if(this.state.available_months[i].date === this.state.selected_month.date){
                this.setState({
                    selected_month: this.state.available_months[i+1],
                    appointment_dates_loaded: false
                });
                return;
            }
        }
    }

    onPreviousMonthClick(e){
        for(let i = 1; i < this.state.available_months.length; i++){
            if(this.state.available_months[i].date === this.state.selected_month.date){
                this.setState({
                    selected_month: this.state.available_months[i-1],
                    appointment_dates_loaded: false
                });
                return;
            }
        }
    }


    getDaysInMonth(month, year){
        return new Date(year, month + 1, 0).getDate();
    }

    onDayBackClicked(e){
        this.setState({
            selected_day: null,
            available_counsellors: []
        });
    }

    onDaySelected(e, year, month, day, day_name){
        axios({
            url: process.env.REACT_APP_BACKEND + '/calendar/get_available_counsellors/' + year + '/' + month + '/' + day,
            method: 'GET',
            withCredentials: true
        }).then(response =>{
            this.setState({
                selected_day: {
                    day: day,
                    month: month,
                    year: year,
                    day_name: day_name,
                    date: month + '/' + day + '/' + year
                },
                available_counsellors: response.data
            })
        }).catch(error =>{
            this.props.showMessage("There are no available councellors for this date", true)
        })
    }

    getAvailableAppointmentDays(){
        if(this.state.appointment_dates_loaded === false){
            axios({
                url: process.env.REACT_APP_BACKEND + '/calendar/get_available_days/' + this.state.selected_month.year + "/"+this.state.selected_month.month,
                method: 'GET',
                withCredentials: true
            }).then(response=>{
                this.setState({
                    available_appointment_days: response.data,
                    appointment_dates_loaded: true
                });
            }).catch(error=>{
                this.props.showMessage(error, false);
            });
        }
    }

    getDayNameFromIndex(index){
        let days = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];

        return days[index];
    }

    AppointmentCalendar(props){
        var selected_month = this.state.selected_month;
        if(selected_month == null){
            return (<div>Loading...</div>);
        }
        let slots = [];
        this.getAvailableAppointmentDays();
        if(!this.state.appointment_dates_loaded){
            return (<div>Loading...</div>);
        }
        //Fill starting empty slots
        for(let i=1; i<selected_month.starting_slot; i++){
            slots.push((<Col 
                key={'empty_slot_' + i} 
                className='empty-day' 
                onClick={()=> this.props.showMessage("Sorry no appointments available on this day", false)}></Col>));
        }

        //Fill slots of valid and available days
        let day_in_week = selected_month.starting_slot - 1;
        let available_appointment_days = this.state.available_appointment_days.map(data=>data.day);
        for(let i=1; i<= selected_month.num_days; i++){
            let current_day_name = this.getDayNameFromIndex(day_in_week);
            
            if(available_appointment_days.indexOf(current_day_name) < 0){
            
                slots.push((<Col 
                    key={'valid_slot_' + i} 
                    className='valid-day' 
                    onClick={()=> this.props.showMessage("Sorry no appointments available on this day", false)}>{i}</Col>));
            }else{
            
                slots.push((<Col 
                    key={'available_slot_' + i} 
                    className='available-day' 
                    onClick={(e)=> this.onDaySelected(e, selected_month.year, selected_month.month + 1, i, current_day_name)}>{i}</Col>));
            }
            day_in_week += 1;
            if(day_in_week === 7){
                day_in_week = 0;
            }
                
        }

        //Fill remaining slots as empty
        let filled_slots = selected_month.starting_slot + selected_month.num_days;
        for(let i=0; i< (42 - filled_slots); i++){
            slots.push((<Col 
                key={'empty_end_slot_' + i} 
                className='empty-day' 
                onClick={()=> this.props.showMessage("Sorry no appointments available on this day", false)}></Col>));
        }

        //Divide days into weeks
        let week1_days, week2_days, week3_days, week4_days,week5_days, week6_days = [];
        week1_days = slots.slice(0, 7);
        week2_days = slots.slice(7, 14);
        week3_days = slots.slice(14, 21);
        week4_days = slots.slice(21, 28);
        week5_days = slots.slice(28, 35);
        week6_days = slots.slice(35);
        
        
        return (
            <Reveal spy={selected_month}>
            <div className='calendar-container'>
                <div className='year-container bg-warning'>
                    <Row>
                        <Col sm>
                            <i onClick={this.onPreviousMonthClick} className='left-calendar-btn ni ni-bold-left'></i>
                        </Col>
                        <Col sm={10}>
                            {selected_month.month_name}
                        </Col>
                        <Col sm>
                            <i onClick={this.onNextMonthClick} className='right-calendar-btn ni ni-bold-right'></i>
                        </Col>
                    </Row>
                </div>
                <div className='day-names-container'>
                    <Row>
                        <Col>Sun</Col>
                        <Col>Mon</Col>
                        <Col>Tue</Col>
                        <Col>Wed</Col>
                        <Col>Thur</Col>
                        <Col>Fri</Col>
                        <Col>Sat</Col>
                    </Row>
                </div>
                <div className='day-nums-container'>
                    <Row className='week-1'>
                        {week1_days}
                    </Row>
                    <Row className='week-2'>
                        {week2_days}
                    </Row>
                    <Row className='week-3'>
                        {week3_days}
                    </Row>
                    <Row className='week-4'>
                        {week4_days}
                    </Row>
                    <Row className='week-5'>
                        {week5_days}
                    </Row>
                    <Row className='week-6'>
                        {week6_days}
                    </Row>
                </div>
            </div>
            </Reveal>
        );
    }

    DayView(props){
        let counsellors = this.state.available_counsellors;

        let time_slots = [
            {time: 8, label: '8 A.M'},
            {time: 9, label: '9 A.M'},
            {time: 10, label: '10 A.M'},
            {time: 11, label: '11 A.M'},
            {time: 12, label: '12 P.M'},
            {time: 13, label: '1 P.M'},
            {time: 14, label: '2 P.M'},
            {time: 15, label: '3 P.M'},
            {time: 16, label: '4 P.M'},
            {time: 17, label: '5 P.M'},
            {time: 18, label: '6 P.M'},
            {time: 19, label: '7 P.M'},
            {time: 20, label: '8 P.M'},
            {time: 21, label: '9 P.M'},
            {time: 22, label: '10 P.M'}
        ];
        
        return (
            <Reveal>
                <div className='day-view-container calendar-container'>
                    <Row>
                        <Col sm={4} className="day-view-left bg-warning">
                            <div className="back-btn-container">
                                <i onClick={this.onDayBackClicked} className='left-calendar-btn ni ni-bold-left'></i>
                            </div>
                            <h1 className="day-num text-white">{this.state.selected_day.day}</h1>
                            <h3 className="day-num text-white">{this.state.selected_day.day_name}</h3>
                            <div className="nearest-appointment-container">
                                <h4>Earliest Appointment:</h4>
                                <p>8:00 AM - Dr. Thompson</p>
                                <Button>Book Now</Button>
                            </div>
                        </Col>
                        <Col sm={8} className="day-view-right ">
                            <h2>Please select an available time below</h2>
                            {(()=>{
                                let hour_views = [];
                                for(let time_slot of time_slots){
                                    let available_councellors = [];
                                    for(let counsellor of counsellors){
                                        let my_slots = counsellor.time_slot.filter(counsellor_time=>{
                                            if(counsellor_time.day === this.state.selected_day.day_name){
                                              
                                                if(Number(time_slot.time) >= Number(counsellor_time.start_time) && Number(time_slot.time) < Number(counsellor_time.end_time)){
                                                    return true;
                                                }
                                            }
                                            return false;
                                        }).map(counsellor_time=> counsellor_time);
                                        if(my_slots.length > 0){
                                            available_councellors.push(counsellor);
                                        }
                                    }
                                    hour_views.push(<this.HourView key={`hv_${time_slot.time}`} time={time_slot} num_counsellors={available_councellors.length} />);
                                }
                                return hour_views;
                            })()}
                        </Col>
                    </Row>
                </div>
            </Reveal>
        );
    }

    HourView(props){
        let time = props.time.label;
        let num_counsellors = props.num_counsellors;
        return (
            <Row onClick={(e)=>{this.props.showMessage("There are no available councellors for this date", false)}} className={`hour-view-container ${num_counsellors === 0 && 'disabled-hour'}`}>
                <Col sm={2} className='hour-view-time-block'>
                    {time}
                </Col>
                <Col sm={7} className='hour-view-description-block'>
                    {num_counsellors} Counsellors are available at this time
                </Col>
                <Col sm={3} className='hour-view-button-block'>
                    Book Now
                </Col>
            </Row>
        );
    }
    render() {
        return (
            <div>
                <div>
                    <div className="header bg-primary pb-6">
                    <div className="container-fluid">
                        <div className="header-body">
                        <div className="row align-items-center py-4">
                            <div className="col-lg-6 col-7">
                            <h6 className="h2 text-white d-inline-block mb-0">Calendar</h6>
                            <nav aria-label="breadcrumb" className="d-none d-md-inline-block ml-md-4">
                                <ol className="breadcrumb breadcrumb-links breadcrumb-dark">
                                <li className="breadcrumb-item"><a href="/app"><i className="fas fa-home" /></a></li>
                                <li className="breadcrumb-item"><a href="/app">Dashboard</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Calendar</li>
                                </ol>
                            </nav>
                            </div>
                            <div className="col-lg-6 col-5 text-right">
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    {/* Page content */}
                    <div className="container-fluid mt--6">
                    <div className="row">
                        <div className="col">
                            <div className="card bg-transparent">
                                {this.state.selected_day === null ? <this.AppointmentCalendar /> : <this.DayView />}
                                
                            </div>
                        </div>
                    </div>
                    
                    </div>
                </div>
            </div>
        );
    }
}