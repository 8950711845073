import React, {Component, useState, useEffect, useRef} from "react";
import axios from "axios";
import Form from "react-bootstrap/Form";
import {Modal, Button, Alert} from "react-bootstrap";
import  io from "socket.io-client";
import {useSpring, animated} from "react-spring";
import {Spring} from 'react-spring/renderprops'

export default function ChatWidget({current_user, chat_type = 'PRIVATE_CHAT', other_user = null, chatroom_id = null}){
    const [clients, setClients] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [chatroomID, setChatroomID] = useState(null);
    const [socket, setSocket] = useState(null);
    const [refresh, setRefresh] = useState(1);
    const messages = useRef([]);
    const [currentMessage, setCurrentMessage] = useState(null);
    const messageInput = useRef();

    useEffect(()=>{
        if(chat_type === 'QUICK_CHAT'){
            setClients([other_user]);
            setupChat(chatroom_id, other_user);
        }
        if(chat_type === 'PRIVATE_CHAT'){
            axios({
                url: process.env.REACT_APP_BACKEND + '/users/getUsers',
                method: 'GET',
                withCredentials: true
            }).then(response => {
                let data = response.data;
                setClients(data);
            }).catch(error=>{
                console.log(error);
            });
        }
        
    }, []);

    function selectUser(user){
        axios({
            url: process.env.REACT_APP_BACKEND + '/users/joinChatroom/' + user._id,
            method: 'GET',
            withCredentials: true
        }).then(response => {
            let _chatroom_id = response.data;
            setupChat(_chatroom_id, user);

        }).catch(error=>{
            console.log(error);
        });
    }

    function setupChat(_chatroom_id, _other_user){
        try{
            setSelectedUser(_other_user);
            messages.current = [];
            if(socket){
                socket.disconnect();
            }
            //Store chatroom id
            setChatroomID(_chatroom_id);

            //Start websocket connection
            const _socket = io(process.env.REACT_APP_BACKEND, 
                {
                    transports: ['websocket'], 
                    query: `chatroom_id=${_chatroom_id}&user_id=${current_user._id}&state=REQUEST_ACCEPTED`
                });
            setSocket(_socket);
            
            // sendMessage(_chatroom_id, _socket, current_user._id + " has joined the chat");
            onMessageReceived(_chatroom_id, _socket);
        }catch(error){
            console.log(error);
        }
        
    }

    function sendMessage(_chatroom_id, _socket, message){
        let _message = {user_id: current_user._id, message: message, event_type: 'IN_DIALOG'};
        _socket.emit(_chatroom_id, _message);
        messages.current.push({
            is_inbound_message:  false,  //It is inbound if the message was sent by the other user
            message: message
        });
        setRefresh(refresh => refresh + 1);
    }

    function onMessageReceived(_chatroom_id, _socket){
        _socket.on(_chatroom_id, data => {
            let {user_id, message, event_type} = data;
            if(event_type === 'IN_DIALOG'){
                messages.current.push({
                    is_inbound_message: (current_user._id === user_id)? false : true,  //It is inbound if the message was sent by the other user
                    message: message
                });
            }
            
            console.log(messages.current);
            setRefresh(refresh => refresh + 1);
            
        }); 
    }

    function onEnter(e){
        if(e.keyCode == 13 && e.shiftKey == false){
            e.preventDefault();
            sendMessage(chatroomID, socket, messageInput.current.value);
        }
    }

    function PeopleWidget(props){
        let user_items = [];
        // console.log(clients);
        for(let i=0; i < clients.length; i++){
            let client = clients[i];
            let icon = client.icon ? "/assets/img/profile_icons/"+client.icon+".png" : "/assets/img/profile_icons/1.png";
            user_items.push(
                <li key={i} className="clearfix user-item" onClick={()=> selectUser(client)}>
                    <img src={icon} alt="avatar" />
                    <div className="about">
                        <div className="name">{client.username} {client.last_name}</div>
                            <div className="status">
                            <i className="fa fa-circle offline"></i> Offline
                        </div>
                    </div>
                </li>
            );
        }

        return user_items;
    }

    function MessagesWidget(props){
        let message_list = [];
        for(let i = 0; i < messages.current.length; i++){
            let message = messages.current[i];
            if(message.is_inbound_message){
                message_list.push(
                    <Spring
                        from={{ opacity: 0, 'margin-top': 20}}
                        to={{ opacity: 1, 'margin-top': 0 }}>
                        {props => 
                                <li key={`message-${i}`} className="clearfix" style={props}>
                                    <div className="message-data align-right">
                                    <span className="message-data-time" >10:10 AM, Today</span> &nbsp; &nbsp;
                                    <span className="message-data-name" >{selectedUser.first_name}</span> <i className="fa fa-circle me"></i>
                                    
                                    </div>
                                    <div className="message other-message float-right">
                                        {message.message}
                                    </div>
                                </li>}
                    </Spring>
                    
                );
            }else{
                message_list.push(
                    <Spring
                        from={{ opacity: 0, 'margin-top': 20}}
                        to={{ opacity: 1, 'margin-top': 0 }}>
                        {props => 
                                <li key={`message-${i}`} style={props}>
                                    <div className="message-data">
                                    <span className="message-data-name"><i className="fa fa-circle online"></i> me</span>
                                    <span className="message-data-time">10:12 AM, Today</span>
                                    </div>
                                    <div className="message my-message">
                                    {message.message}
                                    </div>
                                </li>}
                    </Spring>
                    
                );
            }
            
        }

        return message_list;
    }

    function DialogWidget(props){
        let icon = selectedUser.icon ? "/assets/img/profile_icons/"+selectedUser.icon+".png" : "/assets/img/profile_icons/1.png";
        return (
            <div className="chat">
                <div className="chat-header clearfix">
                    <img src={icon} alt="avatar" />
                    
                    <div className="chat-about">
                    <div className="chat-with">Chat with {selectedUser.username} {selectedUser.last_name}</div>
                    <div className="chat-num-messages">already 1 902 messages</div>
                    </div>
                    <i className="fa fa-star"></i>
                </div> 
                
                <div className="chat-history">
                    <ul>
                    {messages.current.length > 0 && <MessagesWidget />}
                    </ul>
                    
                </div>
                
                <div className="chat-message clearfix">
                    <textarea ref={messageInput} onKeyDown={onEnter} name="message-to-send" id="message-to-send" placeholder ="Type your message" rows="3"></textarea>
                            
                    <i className="fa fa-file-o"></i> &nbsp;&nbsp;&nbsp;
                    <i className="fa fa-file-image-o"></i>
                    
                    <button onClick={()=>{sendMessage(chatroomID, socket, messageInput.current.value)}}>Send</button>
            
                </div>
            
            </div>
        )
    }

    

    return (
        <div className="chat-widget container clearfix">
            <div className="people-list" id="people-list">
                <div className="search">
                    <input type="text" placeholder="search" />
                    <i className="fa fa-search"></i>
                </div>
                <ul className="list">
                    {clients.length > 0 && <PeopleWidget />}
                </ul>
            </div>
            {selectedUser && <DialogWidget />}
        </div>    
    )
}