import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import {Button, Row, Col, Form} from "react-bootstrap";
import {useHistory, useParams} from "react-router-dom";
import SunEditor, { buttonList } from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

export default function EditResourcePage({launchChat, showMessage}) {
    const [title, setTitle] = useState(null);
    const [description, setDescription] = useState(null);
    const [scheduledDate, setScheduledDate] = useState(null);
    const tags = useRef([]);
    const [refresh, setRefresh] = useState(0);
    const history = useHistory();
    const {id} = useParams();

    useEffect(() => {
        if(id){
            axios.get(process.env.REACT_APP_BACKEND + `/resource/get_resource/${id}`, {
                withCredentials: true
            }).then(response => {
                let resource = response.data;
                setTitle(resource.title);
                let _description = atob(resource.description);
                setDescription(_description);
                setScheduledDate(resource.scheduledDate);
                if(resource.tags){
                    tags.current = resource.tags;
                }
                setRefresh(refresh + 1);
                console.log(resource);
            })
            .catch(error => {
                console.log(error.response.data);
            })
        }
        
    }, []);

    
    function onFormSubmit(){
        let resource = {
            title,
            description: Buffer.from(description).toString('base64'),
            scheduledDate,
            tags: tags.current,
        }

        console.log(resource);

        if(!id){
            axios({
                url: process.env.REACT_APP_BACKEND + '/resource/create',
                method: 'POST',
                data: resource,
                withCredentials: true
            }).then(response => {
                showMessage('The resource was created successfully');
                history.push('/app/resources');
            }).catch(error => {
                showMessage(error.response.data.message, false);
            })
        }else{
            axios({
                url: process.env.REACT_APP_BACKEND + '/resource/update/' + id,
                method: 'POST',
                data: resource,
                withCredentials: true
            }).then(response => {
                showMessage('The resource was updated successfully');
                history.push('/app/resources');
            }).catch(error => {
                showMessage(error.response.data.message, false);
            })
        }
        
    }

    function toggleTag(name){
        if(tags.current.indexOf(name) >= 0){
            tags.current = tags.current.filter(tag=>tag !== name);
        }else{
            tags.current.push(name);
        }

        setRefresh(refresh+1);
    }

    return (
        <div>
            <div>
                <div className="header bg-primary pb-6">
                <div className="container-fluid">
                    <div className="header-body">
                    <div className="row align-items-center py-4">
                        <div className="col-lg-6 col-7">
                        <h6 className="h2 text-white d-inline-block mb-0">Edit Resource</h6>
                        <nav aria-label="breadcrumb" className="d-none d-md-inline-block ml-md-4">
                        <ol className="breadcrumb breadcrumb-links breadcrumb-dark">
                            <li className="breadcrumb-item"><a href="/app"><i className="fas fa-home" /></a></li>
                            <li className="breadcrumb-item"><a onClick={()=> {history.push("/app/dashboard")}}>Dashboard</a></li>
                            <li className="breadcrumb-item"><a onClick={()=> {history.push("/app/resources")}}>Resources</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Create Resource</li>
                        </ol>
                        </nav>
                        </div>
                        <div className="col-lg-6 col-5 text-right">
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                {/* Page content */}
                <div className="container-fluid mt--6">
                <div className="row">
                    <div className="col">
                    <div className="card">
                        {/* Card header */}
                        <div className="card-header border-0">
                        <h3 className="mb-0">Edit Resource</h3>
                        </div>
                        {/* Light table */}
                        <div className="card-body">
                            <Form.Group>
                                <Form.Label>Title</Form.Label>
                                <Form.Control onChange={e=>setTitle(e.target.value)} type="text" placeholder="e.g How to build faith" value={title} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Schedule</Form.Label>
                                <Form.Control onChange={e=>setScheduledDate(+new Date(e.target.value.replaceAll('-', '/')))} type="date" value={scheduledDate? new Date(scheduledDate).toLocaleDateString('en-CA') : ''} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Tags</Form.Label><br/>
                                <Form.Check r={refresh} inline type="checkbox" label="Welcome" onChange={e=>toggleTag('Welcome')} checked={tags.current && tags.current.indexOf('Welcome') >= 0} />
                                <Form.Check r={refresh} inline type="checkbox" label="Christian Problems" onChange={e=>toggleTag('Christian Problems')} checked={tags.current && tags.current.indexOf('Christian Problems') >= 0} />
                                <Form.Check r={refresh} inline type="checkbox" label="Excuses Of The Unsaved" onChange={e=>toggleTag('Excuses Of The Unsaved')} checked={tags.current && tags.current.indexOf('Excuses Of The Unsaved') >= 0} />

                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Body</Form.Label>
                            </Form.Group>
                            <SunEditor onChange={(content)=>setDescription(content)} height={300} setContents={description} setOptions={{buttonList: buttons}} />
                            
                        </div>
                        {/* Card footer */}
                        <div className="card-footer py-4">
                            <Button onClick={onFormSubmit}>Save</Button>
                        </div>
                    </div>
                    </div>
                </div>
                
                </div>
            </div>
        </div>
    );
    
}

const buttons = [
    ['undo', 'redo'],
    ['font', 'fontSize', 'formatBlock'],
    ['paragraphStyle', 'blockquote'],
    ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
    ['fontColor', 'hiliteColor', 'textStyle'],
    ['removeFormat'],
    '/', // Line break
    ['outdent', 'indent'],
    ['align', 'horizontalRule', 'list', 'lineHeight'],
    ['table', 'link', 'image', 'video', 'audio' /** ,'math' */], // You must add the 'katex' library at options to use the 'math' plugin.
    /** ['imageGallery'] */ // You must add the "imageGalleryUrl".
    ['fullScreen', 'showBlocks', 'codeView'],
    ['preview', 'print'],
    ['save', 'template'],
    /** ['dir', 'dir_ltr', 'dir_rtl'] */ // "dir": Toggle text direction, "dir_ltr": Right to Left, "dir_rtl": Left to Right
]