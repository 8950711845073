import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import {Button, Row, Col, Form, Carousel} from "react-bootstrap";
import {useHistory, useParams} from "react-router-dom";
import SunEditor, { buttonList } from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';


export default function EditReminderPage({launchChat, showMessage}) {
    const [icon, setIcon] = useState(1);
    const [title, setTitle] = useState(null);
    const [description, setDescription] = useState(null);
    const [summary, setSummary] = useState(null);
    const [scheduledDate, setScheduledDate] = useState(null);
    const tags = useRef([]);
    const [refresh, setRefresh] = useState(0);
    const history = useHistory();
    const {id} = useParams();
    const icons_count = Array.from(Array(111).keys());

    useEffect(() => {
        if(id){
            axios.get(process.env.REACT_APP_BACKEND + `/reminder/get_reminder/${id}`, {
                withCredentials: true
            }).then(response => {
                let reminder = response.data;
                let _description = atob(reminder.description);
                setTitle(reminder.title);
                setDescription(_description);
                setSummary(reminder.summary);
                setScheduledDate(reminder.scheduledDate);
                if(reminder.icon){
                    setIcon(reminder.icon);
                }
                if(reminder.tags){
                    tags.current = reminder.tags;
                }
                setRefresh(refresh + 1);
                console.log(reminder);
            })
            .catch(error => {
                console.log(error.response.data);
            })
        }
        
    }, []);

    
    function onFormSubmit(){
        let reminder = {
            title,
            description: Buffer.from(description).toString('base64'),
            summary,
            scheduledDate,
            tags: tags.current,
            icon
        }

        console.log(reminder);

        if(!id){
            axios({
                url: process.env.REACT_APP_BACKEND + '/reminder/create',
                method: 'POST',
                data: reminder,
                withCredentials: true
            }).then(response => {
                showMessage('The reminder was created successfully');
                history.push('/app/reminders');
            }).catch(error => {
                showMessage(error.response.data.message, false);
            })
        }else{
            axios({
                url: process.env.REACT_APP_BACKEND + '/reminder/update/' + id,
                method: 'POST',
                data: reminder,
                withCredentials: true
            }).then(response => {
                showMessage('The reminder was updated successfully');
                history.push('/app/reminders');
            }).catch(error => {
                showMessage(error.response.data.message, false);
            })
        }
        
    }

    function toggleTag(name){
        if(tags.current.indexOf(name) >= 0){
            tags.current = tags.current.filter(tag=>tag !== name);
        }else{
            tags.current.push(name);
        }

        setRefresh(refresh+1);
    }
    
    return (
        <div>
            <div>
                <div className="header bg-primary pb-6">
                <div className="container-fluid">
                    <div className="header-body">
                    <div className="row align-items-center py-4">
                        <div className="col-lg-6 col-7">
                        <h6 className="h2 text-white d-inline-block mb-0">Edit Reminder</h6>
                        <nav aria-label="breadcrumb" className="d-none d-md-inline-block ml-md-4">
                        <ol className="breadcrumb breadcrumb-links breadcrumb-dark">
                            <li className="breadcrumb-item"><a href="/app"><i className="fas fa-home" /></a></li>
                            <li className="breadcrumb-item"><a onClick={()=> {history.push("/app/dashboard")}}>Dashboard</a></li>
                            <li className="breadcrumb-item"><a onClick={()=> {history.push("/app/reminders")}}>Reminders</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Create Reminder</li>
                        </ol>
                        </nav>
                        </div>
                        <div className="col-lg-6 col-5 text-right">
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                {/* Page content */}
                <div className="container-fluid mt--6">
                <div className="row">
                    <div className="col">
                    <div className="card">
                        {/* Card header */}
                        <div className="card-header border-0">
                        <h3 className="mb-0">Edit Reminder</h3>
                        </div>
                        {/* Light table */}
                        <div className="card-body">
                            <Form.Group>
                                <Form.Label>Title</Form.Label>
                                <Form.Control onChange={e=>setTitle(e.target.value)} type="text" placeholder="e.g Our Father Who Art In heaven" value={title} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Summary</Form.Label>
                                <Form.Control onChange={e=>setSummary(e.target.value)} type="text" placeholder="e.g The lords reminder" value={summary} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Schedule</Form.Label>
                                <Form.Control onChange={e=>setScheduledDate(+new Date(e.target.value.replaceAll('-', '/')))} type="date" value={scheduledDate? new Date(scheduledDate).toLocaleDateString('en-CA') : ''} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Tags</Form.Label><br/>
                                <Form.Check r={refresh} inline type="checkbox" label="Abuse" onChange={e=>toggleTag('abuse')} checked={tags.current && tags.current.indexOf('abuse') >= 0} />
                                <Form.Check  r={refresh} inline type="checkbox" label="Drugs" onChange={e=>toggleTag('drugs')} checked={tags.current && tags.current.indexOf('drugs') >= 0} />
                                <Form.Check  r={refresh} inline type="checkbox" label="Stress" onChange={e=>toggleTag('stress')} checked={tags.current && tags.current.indexOf('stress') >= 0} />
                                <Form.Check  r={refresh} inline type="checkbox" label="Anger" onChange={e=>toggleTag('anger')} checked={tags.current && tags.current.indexOf('anger') >= 0} />
                                <Form.Check  r={refresh} inline type="checkbox" label="Depression" onChange={e=>toggleTag('depression')} checked={tags.current && tags.current.indexOf('depression') >= 0} />
                                <Form.Check  r={refresh} inline type="checkbox" label="Grief" onChange={e=>toggleTag('grief')} checked={tags.current && tags.current.indexOf('grief') >= 0} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Body</Form.Label>
                            </Form.Group>
                            <SunEditor onChange={(content)=>setDescription(content)} height={300} setContents={description} setOptions={{buttonList: buttons}} />
                            <Form.Group>
                                <Form.Label>Select an image</Form.Label>
                                <Row className="p-3">
                                    <Col>
                                        <Carousel activeIndex={icon} onSelect={(index, e)=>setIcon(index)} interval={null} className="bg-default">
                                            {icons_count.map(num=> (
                                                <Carousel.Item className="text-center" key={num}>
                                                    <img width="500" src={`/assets/img/hhu_content_images/photo (${num}).jpg`} />
                                                </Carousel.Item>
                                            ))}
                                        </Carousel>
                                    </Col>
                                </Row>
                            </Form.Group>
                        </div>
                        {/* Card footer */}
                        <div className="card-footer py-4">
                            <Button onClick={onFormSubmit}>Save</Button>
                        </div>
                    </div>
                    </div>
                </div>
                
                </div>
            </div>
        </div>
    );
    
}

const buttons = [
    ['undo', 'redo'],
    ['font', 'fontSize', 'formatBlock'],
    ['paragraphStyle', 'blockquote'],
    ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
    ['fontColor', 'hiliteColor', 'textStyle'],
    ['removeFormat'],
    '/', // Line break
    ['outdent', 'indent'],
    ['align', 'horizontalRule', 'list', 'lineHeight'],
    ['table', 'link', 'image', 'video', 'audio' /** ,'math' */], // You must add the 'katex' library at options to use the 'math' plugin.
    /** ['imageGallery'] */ // You must add the "imageGalleryUrl".
    ['fullScreen', 'showBlocks', 'codeView'],
    ['preview', 'print'],
    ['save', 'template'],
    /** ['dir', 'dir_ltr', 'dir_rtl'] */ // "dir": Toggle text direction, "dir_ltr": Right to Left, "dir_rtl": Left to Right
]