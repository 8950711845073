import React, {Component} from "react";
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import {Spinner} from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from "./layouts/admin.layout";
import PublicLayout from "./layouts/public.layout";
import LoginPage from "./pages/login.page";
import ClientPage from "./pages/clients.page";
import axios from "axios";
import SchedulePage from "./pages/schedule.page";
import DashboardPage from "./pages/dashboard.page";
import CalendarPage from "./pages/calendar.page";
import NotFoundPage from "./pages/404.page";
import ChatPage from "./pages/chat.page";
import ChatWidget from "./components/chat.component";
import CounsellorPage from "./pages/counsellors.page";
import CreateCounsellorPage from "./pages/create_counsellor.page";
import ChatRequestsPage from "./pages/chat_requests.page";
import EditArticlePage from "./pages/edit_article.page";
import ArticlesPage from "./pages/articles.page";
import ResourcesPage from "./pages/resources.page";
import EditResourcePage from "./pages/edit_resource.page";
import PrayersPage from "./pages/prayers.page";
import ConsentFormsPage from "./pages/consent_forms.page";
import RemindersPage from "./pages/reminders.page";
import ScripturesPage from "./pages/scriptures.page";
import PrivacyPolicyPage from "./pages/privacy_policy";
import ShowArticlePage from "./pages/show_article.page";
import ShowResourcePage from "./pages/show_resource.page";
import ShowPrayerPage from "./pages/show_prayer.page";
import ShowReminderPage from "./pages/show_reminder.page";
import EditPrayerPage from "./pages/edit_prayer.page";
import EditConsentFormPage from "./pages/edit_consent_form.page";
import EditReminderPage from "./pages/edit_reminder.page";
import ShowConsentFormPage from "./pages/show_consent_form.page";
import HomePage from "./pages/home.page";
import EditScripturePage from "./pages/edit_scripture.page";


const showMessage = (message, success = true) => {
    if(success){
        toast.success(message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
    }else{
        toast.error(message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
    }
}
const AdminRoutes = props => (
    <Router>
        <Switch>
            <Route path='/' exact>
                <Redirect to='/app/dashboard'/>
            </Route>
            <Route path='/app' exact>
                <Redirect to='/app/dashboard'/>
            </Route>
            {/* <Route path='/app' exact component={() => <AdminLayout user={props.user} />} /> */}
            <Route path='/app/questionnare' exact component={() => <AdminLayout user={props.user} />} />
            <Route path='/app/clients' exact component={() => <AdminLayout user={props.user} page={<ClientPage launchChat={props.launchChat} />} tab='clients' />} />
            <Route path='/app/counsellors' exact component={() => <AdminLayout user={props.user} page={<CounsellorPage  launchChat={props.launchChat} />} tab='counsellors' />} />
            <Route path='/app/create_counsellor' exact component={() => <AdminLayout user={props.user} page={<CreateCounsellorPage showMessage={showMessage}  launchChat={props.launchChat} />} tab='counsellors' />} />
            <Route path='/app/create_counsellor/:id' exact component={() => <AdminLayout user={props.user} page={<CreateCounsellorPage showMessage={showMessage}  launchChat={props.launchChat} />} tab='counsellors' />} />
            <Route path='/app/chat_requests' exact component={() => <AdminLayout user={props.user} page={<ChatRequestsPage showMessage={showMessage}  launchChat={props.launchChat} />} tab='chat_requests' />} />
            <Route path='/app/schedule' exact component={() => <AdminLayout user={props.user} page={<SchedulePage showMessage={showMessage}  launchChat={props.launchChat} />} tab='schedule' />} />
            <Route path='/app/dashboard' exact component={() => <AdminLayout user={props.user} page={<DashboardPage />} tab='dashboard' />}  launchChat={props.launchChat} />
            <Route path='/app/calendar' exact component={() => <AdminLayout user={props.user} page={<CalendarPage showMessage={showMessage}  launchChat={props.launchChat} />} tab='calendar' />} />
            <Route path='/app/meeting' exact component={() => <ChatPage current_user={props.user} showMessage={showMessage}  launchChat={props.launchChat} />} />
            <Route path='/app/edit_article' exact component={() => <AdminLayout user={props.user} page={<EditArticlePage showMessage={showMessage}  launchChat={props.launchChat} />} tab='edit_article' />} />
            <Route path='/app/edit_article/:id' exact component={() => <AdminLayout user={props.user} page={<EditArticlePage showMessage={showMessage}  launchChat={props.launchChat} />} tab='edit_article' />} />
            <Route path='/app/edit_resource' exact component={() => <AdminLayout user={props.user} page={<EditResourcePage showMessage={showMessage}  launchChat={props.launchChat} />} tab='edit_resource' />} />
            <Route path='/app/edit_resource/:id' exact component={() => <AdminLayout user={props.user} page={<EditResourcePage showMessage={showMessage}  launchChat={props.launchChat} />} tab='edit_resource' />} />
            <Route path='/app/resources' exact component={() => <AdminLayout user={props.user} page={<ResourcesPage showMessage={showMessage}  launchChat={props.launchChat} />} tab='resources' />} />
            <Route path='/show_resource/:id' exact component={() => <ShowResourcePage />} />
            <Route path='/app/edit_consent_form' exact component={() => <AdminLayout user={props.user} page={<EditConsentFormPage showMessage={showMessage}  launchChat={props.launchChat} />} tab='edit_prayer' />} />
            <Route path='/app/edit_consent_form/:id' exact component={() => <AdminLayout user={props.user} page={<EditConsentFormPage showMessage={showMessage}  launchChat={props.launchChat} />} tab='edit_prayer' />} />
            <Route path='/app/articles' exact component={() => <AdminLayout user={props.user} page={<ArticlesPage showMessage={showMessage}  launchChat={props.launchChat} />} tab='articles' />} />
            <Route path='/app/edit_prayer' exact component={() => <AdminLayout user={props.user} page={<EditPrayerPage showMessage={showMessage}  launchChat={props.launchChat} />} tab='edit_prayer' />} />
            <Route path='/app/edit_prayer/:id' exact component={() => <AdminLayout user={props.user} page={<EditPrayerPage showMessage={showMessage}  launchChat={props.launchChat} />} tab='edit_prayer' />} />
            <Route path='/app/prayers' exact component={() => <AdminLayout user={props.user} page={<PrayersPage showMessage={showMessage}  launchChat={props.launchChat} />} tab='prayers' />} />
            <Route path='/app/consent_forms' exact component={() => <AdminLayout user={props.user} page={<ConsentFormsPage showMessage={showMessage}  launchChat={props.launchChat} />} tab='consent_forms' />} />
            <Route path='/app/edit_reminder' exact component={() => <AdminLayout user={props.user} page={<EditReminderPage showMessage={showMessage}  launchChat={props.launchChat} />} tab='edit_reminder' />} />
            <Route path='/app/edit_reminder/:id' exact component={() => <AdminLayout user={props.user} page={<EditReminderPage showMessage={showMessage}  launchChat={props.launchChat} />} tab='edit_reminder' />} />
            <Route path='/app/edit_scripture' exact component={() => <AdminLayout user={props.user} page={<EditScripturePage showMessage={showMessage}  launchChat={props.launchChat} />} tab='edit_scripture' />} />
            <Route path='/app/edit_scripture/:id' exact component={() => <AdminLayout user={props.user} page={<EditScripturePage showMessage={showMessage}  launchChat={props.launchChat} />} tab='edit_scripture' />} />
            <Route path='/app/reminders' exact component={() => <AdminLayout user={props.user} page={<RemindersPage showMessage={showMessage}  launchChat={props.launchChat} />} tab='reminders' />} />
            <Route path='/app/scriptures' exact component={() => <AdminLayout user={props.user} page={<ScripturesPage showMessage={showMessage}  launchChat={props.launchChat} />} tab='scriptures' />} />
            <Route path='/show_article/:id' exact component={() => <ShowArticlePage />} />
            <Route path='/show_prayer/:id' exact component={() => <ShowPrayerPage />} />
            <Route path='/show_consent_form/:id' exact component={() => <ShowConsentFormPage />} />
            <Route path='/show_reminder/:id' exact component={() => <ShowReminderPage />} />
            <Route component={NotFoundPage} />
        </Switch>
    </Router>
)

const PublicRoutes = props => (
    <Router>
        <Switch>
            <Route path='/' exact component={() => <PublicLayout page={<HomePage showMessage={showMessage} />} />} />
            <Route path='/hm_login' exact component={() => <PublicLayout page={<LoginPage showMessage={showMessage} />} />} />
            <Route path='/privacy' exact component={() => <PublicLayout page={<PrivacyPolicyPage showMessage={showMessage} />} />} />
            <Route path='/show_article/:id' exact component={() => <ShowArticlePage />} />
            <Route path='/show_resource/:id' exact component={() => <ShowResourcePage />} />
            <Route path='/show_prayer/:id' exact component={() => <ShowPrayerPage />} />
            <Route path='/show_reminder/:id' exact component={() => <ShowReminderPage />} />
            <Route path='/show_consent_form/:id' exact component={() => <ShowConsentFormPage />} />


            <Route path='/app'>
                <Redirect to='/'/>
            </Route>
            <Route component={NotFoundPage} />
        </Switch>
    </Router>
);


export default class Routes extends Component {

    constructor(props) {
        super(props);

        this.isUserLoggedIn = this.isUserLoggedIn.bind(this);
        this.MessageBar = this.MessageBar.bind(this);
        this.launchChat = this.launchChat.bind(this);


        this.state = {
            currentUser: null,
            loaded: false,
            other_user: null,
            show_chat: false,
            chatroom_id: null,
            chatroom_type: 'PRIVATE_CHAT'
        };
    }

    componentDidMount(){
        this.isUserLoggedIn();
    }

    

    isUserLoggedIn(){
        axios.get(process.env.REACT_APP_BACKEND + '/users/getLoggedInUser', { withCredentials: true})
        .then(response => {
            var user = response.data
            this.setState({
                user: user,
                loaded: true
            });
        })
        .catch(error => {
            this.setState({
                loaded: true
            });
            // console.log(error.response.data);
        })
        
    }

    

    MessageBar(props){
        return (
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                />
        );
        
    }

    launchChat(chatroom_id, other_user, type){
        this.setState({
            chatroom_id: chatroom_id,
            other_user: other_user,
            chatroom_type: type,
            show_chat: true
        });
    }

    render() {
        return (
            <div>
                <this.MessageBar />
                {this.state.show_chat && <ChatWidget current_user={this.state.user} other_user={this.state.other_user} chatroom_id={this.state.chatroom_id} chat_type={this.state.chatroom_type} />}
                {(() => {
                    if(this.state.loaded){
                        if(this.state.user){
                            return <AdminRoutes user={this.state.user} launchChat={this.launchChat} />;
                        }else{
                            return <PublicRoutes />;
                        }
                    }else{
                        return(
                            <div className='bg-dark loading-screen'>
                                <Spinner animation="grow" variant="dark" />
                            </div>
                        );
                    }
                })()
                }
            </div>
        );
    }
}

