import { BrowserRouter as Router, Route } from "react-router-dom";
import Routes from './Routes';
// import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <Routes />
  );
}

export default App;
