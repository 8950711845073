import React, {Component} from "react";
// import blue from "../assets/img/brand/blue.png";
import FootData from "../components/foot-data.component";
// import '../components/head.component';

export default class PublicLayout extends Component{


    render() {
        return (
            <div>
                {this.props.page}
                <FootData/>
            </div>
        )
    }
}